// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDoc, doc, setDoc, updateDoc } from "firebase/firestore";

// Initialize Firebase
// const analytics = getAnalytics(app);

const firebaseConfig = require('./firebase_config.js');
const app = initializeApp(firebaseConfig);
const db = getFirestore();

const provider = new GoogleAuthProvider();
const auth = getAuth();

// Sign in and check or create account in firestore
const signInWithGoogle = () => {
  signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;

      if (token) {
        const user = result.user;
        const user_ref = doc(db, "users", user.uid);
         getDoc(user_ref).then((u_user) => {
          if (!u_user.exists()) {
            // create a new user
            setDoc(doc(db, "users", user.uid), {
              uid: user.uid,
              nickname: user.displayName
            });
          } else {
            updateDoc(doc(db, "users", user.uid), {
              logins: 'again'
            });
          }
          return result;
        }).then( (result) => {
          const user = result.user;
          const ref = doc(db, "user_roles", result.user.uid);
           getDoc(ref).then((u_role) => {
            if (!u_role.exists()) {
              setDoc(doc(db, "user_roles", user.uid), {
                role: 'new',
                allowed: true
              });
            }
          });
        });
      }
    }).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      // const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
      console.log(errorCode, errorMessage, email);
    });
};

const logout = () => {
  auth.signOut();
};

export { app, auth, db, signInWithGoogle, logout };
