// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
//
// Google says this is not really a secret. I hope they are right.
const firebaseConfig = {
  apiKey: "AIzaSyDJjJzWS6RAnKkPgBiTyAi5jop5FCM9TmY",
  authDomain: "fir-and-ecc6f.firebaseapp.com",
  projectId: "fir-and-ecc6f",
  storageBucket: "fir-and-ecc6f.appspot.com",
  messagingSenderId: "942594402643",
  appId: "1:942594402643:web:6eb25dfda8a246c78bd9a6",
  measurementId: "G-H45M0HTZ8L"
};
module.exports = firebaseConfig;
// export { firebaseConfig };
