const QuizCupDummyQuiz =
 {
   game_format: 'Quiz Cup',
   author: 'Quizzing.TV',
   name: 'Champions League S1 - Week 5 Final',
   date: 'unknown',
  "quiz": [
    {
      "round": [
        {
          "c": "1970s",
          "q": "During the heyday of CBS in the 1970s, who was frequently cited as \"the most trusted man in America\"?",
          "a": "Walter CRONKITE",
          "notes": ""
        },
        {
          "c": "1970s",
          "q": "Around four million grief-stricken Egyptians lined the streets of Cairo in 1975 for the funeral of which internationally famous singer?",
          "a": "Umm KULTHUM",
          "notes": ""
        },
        {
          "c": "Art museums",
          "q": "Perhaps most famously home to 'Girl with a Pearl Earring', which art museum in The Hague contains works by Rembrandt, Vermeer, Hals, and Holbein the Younger, among others?",
          "a": "MAURITSHUIS",
          "notes": ""
        },
        {
          "c": "Art museums",
          "q": "Which US state capital is home to the Wadsworth Atheneum, the oldest continually operating public art museum in the country?",
          "a": "HARTFORD",
          "notes": ""
        },
        {
          "c": "Comedy films",
          "q": "Which 1980 film, originally titled “Flying High”, features the famous line: “Surely you can't be serious?”?",
          "a": "AIRPLANE!",
          "notes": ""
        },
        {
          "c": "Comedy films",
          "q": "Russell Crowe and Ryan Gosling star in which 2016 crime-actioncomedy film centred on a pair of Los Angeles private investigators in 1977 looking into the case of a missing girl?",
          "a": "The NICE GUYS",
          "notes": ""
        },
        {
          "c": "Fashion",
          "q": "In 2009, Michelle Obama wore which Belgian-born fashion designer's signature Chain Link print wrap dress on the official White House Christmas card?",
          "a": "Diane von FURSTENBERG",
          "notes": ""
        },
        {
          "c": "Fashion",
          "q": "The Turkish-born Spanish businessman Isak Andic founded which fashion retail chain that has been fronted by Kate Moss and Miranda Kerr?",
          "a": "MANGO",
          "notes": ""
        },
        {
          "c": "Formula One",
          "q": "The Yas Marina circuit is the venue for which Formula One Grand Prix?",
          "a": "ABU DHABI Grand Prix",
          "notes": ""
        },
        {
          "c": "Formula One",
          "q": "Which northern Italian town is best known as the home of Ferrari and the Scuderia Ferrari F1 racing team?",
          "a": "MARANELLO",
          "notes": ""
        },
        {
          "c": "Historical quotes",
          "q": "What three-word Latin phrase is attributed to Julius Caesar who delivered it in a letter to the Roman Senate following victory at the Battle of Zela?",
          "a": "VENI, VIDI, VICI",
          "notes": ""
        },
        {
          "c": "Historical quotes",
          "q": "“Those who do not remember the past are condemned to repeat it”, is a quote widely attributed to which Spanish-born philosopher (1863-1952)?",
          "a": "George SANTAYANA",
          "notes": ""
        },
        {
          "c": "Human body",
          "q": "What is the largest sesamoid bone in the human body?",
          "a": "PATELLA / Kneecap",
          "notes": ""
        },
        {
          "c": "Human body",
          "q": "Which 17th century English physician is credited with being the first known person to describe completely, and in detail, the systemic circulation and properties of blood being pumped to the brain and the rest of the body by the heart?",
          "a": "William HARVEY",
          "notes": ""
        },
        {
          "c": "Landmark Buildings",
          "q": "Along with architects André and Jean Polak, which engineer designed the Atomium for the Expo 58 World's Fair in Brussels?",
          "a": "André WATERKEYN",
          "notes": ""
        },
        {
          "c": "Landmark Buildings",
          "q": "Which architect designed the Biosphere that served as the US Pavilion for Expo 67 in Montreal?",
          "a": "R. [Richard] Buckminster FULLER",
          "notes": ""
        },
        {
          "c": "Musicals",
          "q": "The musical 'Guys and Dolls' was based upon two short stories by which newspaperman?",
          "a": "Damon RUNYON",
          "notes": ""
        },
        {
          "c": "Musicals",
          "q": "Which 1922 Broadway musical revue featured the songs \"Yes! We Have No Bananas\" and \"The Sheik of Araby\"?  ",
          "a": "MAKE IT SNAPPY",
          "notes": ""
        },
        {
          "c": "Mythology",
          "q": "Which king of Ephyra, now known as Corinth, in Greek myth was punished for twice cheating death by being forced to roll a massive boulder up a hill, only for it to roll down again once it reached the top?",
          "a": "SISYPHUS",
          "notes": ""
        },
        {
          "c": "Mythology",
          "q": "Owning the horse Gulltoppr, which giant and son of Nine Mothers in Norse mythology guards the rainbow bridge Bifrost and is known for drinking fine mead in his dwelling Himinbjorg?",
          "a": "HEIMDALLR",
          "notes": ""
        },
        {
          "c": "Political writings",
          "q": "The banality of evil\" was a phrase famously introduced in the subtitle of which 1963 book by political theorist Hannah Arendt?",
          "a": "EICHMANN IN JERUSALEM",
          "notes": ""
        },
        {
          "c": "Political writings",
          "q": "Which revolutionary anarchist wrote works such as 'Statism and Anarchy', and 'God and the State'?",
          "a": "Mikhail BAKUNIN",
          "notes": ""
        },
        {
          "c": "Tourist Attractions",
          "q": "The cover of the Beach Boys' 1966 album 'Pet Sounds' shows the band members feeding goats at which Californian zoo, the most visited in North America?",
          "a": "SAN DIEGO",
          "notes": ""
        },
        {
          "c": "Tourist Attractions",
          "q": "Home to animals such as Knut the polar bear and Bao Bao the giant panda, which European capital's Zoological Gardens is the mostvisited zoo in Europe?",
          "a": "BERLIN",
          "notes": ""
        },
        {
          "c": "Mystery Box A",
          "q": "Which sculpture by Michelangelo was damaged by Hungarian-born Laszlo Toth in 1972 with a geologist's hammer?",
          "a": "PIETA",
          "notes": ""
        },
        {
          "c": "Mystery Box A",
          "q": "John Galliano, former head designer of fashion companies Givenchy, Christian Dior and his own label, is currently the creative director of which Paris-based fashion house? ",
          "a": "Maison MARGIELA [Acc: Maison MARTIN MARGIELA - former name]",
          "notes": ""
        },
        {
          "c": "Mystery Box A",
          "q": "Which officer of the US Navy is known for his order at the Battle of Mobile Bay during the American Civil War: “Damn the torpedoes, full speed ahead!”?",
          "a": "David FARRAGUT",
          "notes": ""
        },
        {
          "c": "Mystery Box B",
          "q": "Which writer's work 'Tevye and His Daughters' was the basis for the musical 'Fiddler on the Roof'?",
          "a": "Sholem ALEICHEM / Solomon Rabinovich",
          "notes": ""
        },
        {
          "c": "Mystery Box B",
          "q": "Some say that Tāne, a god of forests and birds in Maori myth created the first man from sand. What name was given to this man?",
          "a": "TIKI",
          "notes": ""
        },
        {
          "c": "Mystery Box B",
          "q": "The British architectural firm Foster+Partners designed an enclosure with heated floors and a section that mimics a dry riverbed at the edge of a rainforest to house what mammals at Copenhagen Zoo?",
          "a": "ELEPHANTS",
          "notes": ""
        }
      ]
    },
    {
      "round": [
        {
          "c": "Brazil",
          "q": "Brazil borders all other South American countries except for Ecuador and which other?",
          "a": "CHILE",
          "notes": ""
        },
        {
          "c": "Brazil",
          "q": "Born in 1986, which Brazilian is widely regarded as the greatest female footballer of all time? She won both the Golden Ball award as the best player and the Golden Boot award as the top scorer in the 2007 Women's World Cup.",
          "a": "MARTA Vieira da Silva",
          "notes": ""
        },
        {
          "c": "Business",
          "q": "Which multinational personal care corporation owns brands including Huggies and Kleenex?",
          "a": "KIMBERLY-CLARK",
          "notes": ""
        },
        {
          "c": "Business",
          "q": "Charlene de Carvalho, the richest person with Dutch citizenship, owns a 25% controlling stake in which brand?",
          "a": "HEINEKEN",
          "notes": ""
        },
        {
          "c": "Cover versions",
          "q": "Which Japanese rock trio are perhaps best known for their cover of the track \"Woo Hoo\" that was featured in Kill Bill: Vol. 1?",
          "a": "The 5678s",
          "notes": ""
        },
        {
          "c": "Cover versions",
          "q": "Don't Let Me Be Misunderstood\" is famous for having been covered by The Animals, but which singer and civil rights activist originally released it in 1964?",
          "a": "Nina SIMONE / Eunice Kathleen Waymon (birth name)",
          "notes": ""
        },
        {
          "c": "Fictional policemen",
          "q": "Who was the author of the series of novels featuring Chief Inspector Kurt Wallender?",
          "a": "Henning MANKELL",
          "notes": ""
        },
        {
          "c": "Fictional policemen",
          "q": "Who was the author of the series of novels featuring Inspector Salvo Montalbano?",
          "a": "Andrea CAMILLERI",
          "notes": ""
        },
        {
          "c": "Film roles",
          "q": "The roles of which well-known US actor include the title characters in 'Turner & Hooch' and 'Captain Phillips'?",
          "a": "Tom HANKS",
          "notes": ""
        },
        {
          "c": "Film roles",
          "q": "Which Italian actress earned the Academy Award for Best Actress for her performance as Cesira in 'Two Women' (1961), making her the first actor to win an Oscar for a foreign-language performance?",
          "a": "Sophia LOREN",
          "notes": ""
        },
        {
          "c": "Flags",
          "q": "Although the flags of Saudi Arabia and Moldova have mirrored reverse and obverse sides, which is the only national flag to have two different sides, with its coat of arms on the obverse?",
          "a": "PARAGUAY",
          "notes": ""
        },
        {
          "c": "Flags",
          "q": "Said to be the world's oldest national flag, legend has it that the Danish national flag fell from the sky just as the Danes were about to lose which battle of 1219?",
          "a": "Battle of LINDANISE / Battle of Valdemar",
          "notes": ""
        },
        {
          "c": "SI",
          "q": "Sometimes abbreviated to \"sr\", what is the SI derived unit of solid angle?",
          "a": "STERADIAN / Square Radian",
          "notes": ""
        },
        {
          "c": "SI",
          "q": "Which Swedish physicist, known for his work on the biological effects of radiation, gives his name to the unit used to measure ionising radiation dose on the body?",
          "a": "Rolf SIEVERT",
          "notes": ""
        },
        {
          "c": "Non-fiction",
          "q": "Complete the title of the 1997 Jared Diamond bestseller that won a Pulitzer Prize, 'Guns, Germs, and …' what?",
          "a": "STEEL",
          "notes": ""
        },
        {
          "c": "Non-fiction",
          "q": "Which historian, a two-time Pulitzer Prize-winner, wrote 'The Guns of August' (1962) and 'Stilwell and the American Experience in China, 1911-45' (1971)?",
          "a": "Barbara TUCHMAN",
          "notes": ""
        },
        {
          "c": "Olympic sports",
          "q": "Fiji won its first ever Olympic medal of any description at the 2016 Summer Games, when they beat England 43-7 in the men’s final of which event? I need a two-word answer please.",
          "a": "RUGBY SEVENS",
          "notes": ""
        },
        {
          "c": "Olympic sports",
          "q": "Jay Gould II who took gold, Eustace Miles who won the silver, and Neville Bulwer-Lytton with bronze, are the only ever medallists at what Olympic sport that took place at the 1908 Games?",
          "a": "JEU DE PAUME / REAL TENNIS / COURT TENNIS",
          "notes": ""
        },
        {
          "c": "Punk",
          "q": "Enema of the State' is the third studio album of which pop punk band that was formed in 1992 by Mark Hoppus, Tom DeLonge and Scott Raynor?",
          "a": "BLINK-182",
          "notes": ""
        },
        {
          "c": "Punk",
          "q": "Said to be the first punk band outside the USA to have a released a record, which Australian group released albums such as '(I'm) Stranded', and 'Eternally Yours'?",
          "a": "The SAINTS",
          "notes": ""
        },
        {
          "c": "Railways",
          "q": "In 1964, the Tokaido Shinkansen high-speed train made its first official trip from Tokyo to which other city?",
          "a": "OSAKA",
          "notes": ""
        },
        {
          "c": "Railways",
          "q": "The first country-wide rail system in Latin America opened in 1837 on what Caribbean island, mainly to serve its sugar industry?",
          "a": "CUBA",
          "notes": ""
        },
        {
          "c": "Religious movements",
          "q": "Which religious denomination frequently distributes door-to-door magazines such as 'The Watchtower'?",
          "a": "JEHOVAH'S WITNESSES",
          "notes": ""
        },
        {
          "c": "Religious movements",
          "q": "Recognized by scholars as the fourth major stream of Judaism - after movements Orthodox, Conservative, and Reform - which modern Jewish movement was founded by Rabbi Mordecai Kaplan and his son-in-law Rabbi Ira Einstein in the first half of the 20th century and views Judaism as a progressively evolving civilization? ",
          "a": "RECONSTRUCTIONIST Judaism",
          "notes": ""
        },
        {
          "c": "Mystery Box A",
          "q": "Which group's 1969 live album 'Kick Out the Jams' is seen as a protopunk album, and an important forerunner to the punk scene as a whole?",
          "a": "MC5",
          "notes": ""
        },
        {
          "c": "Mystery Box A",
          "q": "Melina Mercouri received an Academy Award nomination for her role in which 1960 film written and directed by Jules Dassin?",
          "a": "NEVER ON SUNDAY",
          "notes": ""
        },
        {
          "c": "Mystery Box A",
          "q": "Known for his abolitionist and republican works, which Brazilian poet and playwright was dubbed \"the Poet of the Slaves\"?",
          "a": "Castro ALVES",
          "notes": ""
        },
        {
          "c": "Mystery Box B",
          "q": "A feat only matched by Carl Lewis in the long jump, who was the first athlete to win a gold medal in the same individual event in four consecutive Olympics?",
          "a": "Al OERTER",
          "notes": ""
        },
        {
          "c": "Mystery Box B",
          "q": "Which 20th-century Korean religious movement has a name meaning \"religion of the heavenly way\"?",
          "a": "CHEONDOISM / Chondoism",
          "notes": ""
        },
        {
          "c": "Mystery Box B",
          "q": "Who is the author of the series of novels set in Tsarist Russia and featuring the detective Erast Fandorin?",
          "a": "Boris AKUNIN",
          "notes": ""
        }
      ]
    }
  ]
 };

/*
const QuizCupDummyQuiz =
 {
   game_format: 'Quiz Cup',
   author: 'Quizzing.TV',
   name: 'Champions League S1 - Week 5 Final',
   date: 'unknown',
  "quiz": [
    {
      "round": [

        {
          "c": "Mystery Box B",
          "q": "Which writer's work 'Tevye and His Daughters' was the basis for the musical 'Fiddler on the Roof'?",
          "a": "Sholem ALEICHEM / Solomon Rabinovich",
          "notes": ""
        },
        {
          "c": "Mystery Box B",
          "q": "Some say that Tāne, a god of forests and birds in Maori myth created the first man from sand. What name was given to this man?",
          "a": "TIKI",
          "notes": ""
        },
        {
          "c": "Mystery Box B",
          "q": "The British architectural firm Foster+Partners designed an enclosure with heated floors and a section that mimics a dry riverbed at the edge of a rainforest to house what mammals at Copenhagen Zoo?",
          "a": "ELEPHANTS",
          "notes": ""
        }
      ]
    },
    {
      "round": [
        {
          "c": "Brazil",
          "q": "Brazil borders all other South American countries except for Ecuador and which other?",
          "a": "CHILE",
          "notes": ""
        },
        {
          "c": "Brazil",
          "q": "Born in 1986, which Brazilian is widely regarded as the greatest female footballer of all time? She won both the Golden Ball award as the best player and the Golden Boot award as the top scorer in the 2007 Women's World Cup.",
          "a": "MARTA Vieira da Silva",
          "notes": ""
        }
      ]
    }
  ]
 };
*/

export default QuizCupDummyQuiz;
