import "./Dashboard.css";
import React from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import { updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { joinDialogAtom } from "../utils/atoms";
import { GameFormatByName } from "./GameFormats"

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

const joinGameInstance = async (game, uid, role) => {
  try {
    if (!game.exists) {
      return alert(`Game no longer exists, sorry!`);
    }
    let gameInstanceData = game.data();

    let tempParticipants = gameInstanceData.participants || [];
    let tempParticipantRoles = gameInstanceData.participant_roles || {};

    if (tempParticipants.includes(uid))
    {
      return alert(`You are already in there!`);
    }

    tempParticipants.push(uid);
    tempParticipantRoles[uid] = role;

    let new_data = {
      participants: tempParticipants,
      participant_roles: tempParticipantRoles
    }
    console.log('join', new_data);
    updateDoc(game.ref, new_data);

  } catch (error) {
    console.error(error.message);
  }
};

const JoinGameInstance = ({ gameInstanceData }) => { // a special dialogue for this purpose
  const [user] = useAuthState(auth);
  const setOpen = useSetRecoilState(joinDialogAtom);
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
    navigate("/");
  };

  const this_game = gameInstanceData;
  const hosts = this_game.data().participants.filter(participant => this_game.data().participant_roles[participant] === 'host');
  const game_format = GameFormatByName(this_game.data().game_format);
  let join_buttons = [];
  let idx = 999;

  if (game_format.requires_host && hosts.length === 0) {
    join_buttons.push((<Button key={ 'jb-' + idx + '-host'} onClick={ () => { joinGameInstance(this_game, user.uid, 'host') } } color="primary"> Join as Host</Button>))
  }
  if (game_format.team_count > 0) {
      for (let i = 0; i < game_format.team_count; i++) {
        join_buttons.push((<Button key={ 'jb-' + idx + '-' + i} onClick={ () => { joinGameInstance(this_game, user.uid, 'team ' + (i + 1)) } } color="primary"> Join Team {i+1} </Button>))
      }
  } else {
    join_buttons.push((<Button key={ 'jb-' + idx + '-join' } onClick={ () => { joinGameInstance(this_game, user.uid, 'player') } } color="primary"> Join Game</Button>));
  }
  if (true) { // TODO: spectators allowed ?
    join_buttons.push((<Button key={ 'jb-' + idx + '-watch' } onClick={ () => { joinGameInstance(this_game, user.uid, 'spectator') } } color="primary"> Spectate</Button>));
  }

  return (
    <div>
      <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Join { this_game.name }</DialogTitle>
        <DialogContent>
          { join_buttons }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export { joinGameInstance, JoinGameInstance };
