import React from 'react';
import { auth, db } from "../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { currentGameAtom } from "../utils/atoms";
import { useSetRecoilState } from "recoil";
import { Button } from "@material-ui/core";

const LeaveGameInstance = ({ gameInstanceData, gameId, message, confirm, className }) => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const setCurrentGame = useSetRecoilState(currentGameAtom);

  const leaveGameInstance = async () => {
    if (!confirm || window.confirm("Really exit the game?")) {
      const ref = doc(db, "game_instances", gameInstanceData?.id || gameId);
      const result = await getDoc(ref);
      console.log(result);

      if (result.exists()) {
        let tempParticipants = (result.data().participants || []).filter((el) => el !== user.uid );

        updateDoc(ref, {
          participants: tempParticipants
        });
        setCurrentGame(null);
        navigate(`/dashboard`)
      }
    }
  }

  return (<Button className={ `leave-button ${className}` }  onClick={ leaveGameInstance }>{ message || 'Leave Game' }</Button>);
};

export default LeaveGameInstance;
