import React, { useState } from 'react';
import { Button } from "@material-ui/core";

const Shutter = ({ name, animation, children, startHidden}) => {
  const [hidden, setHidden] = useState(startHidden || false);

  const hide = (value) => {
    setHidden(value);
  }

  return(<div className={ `shutterable chatbox-handle` } animation={ animation }>
            <div className="shutterable-header">
              <div>{ name }</div>
                <Button onClick={ () => hide(!hidden) }>{ hidden ? 'unhide' : 'hide' }</Button>
              </div>
              <div style={ { display: hidden ? 'none' : 'block' } }>
               {children}
              </div>

          </div>);
}

export default Shutter;
