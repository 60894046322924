import { atom } from "recoil";
const joinDialogAtom = atom({
  key: "joinDialogAtom",
  default: false,
});
const createDialogAtom = atom({
  key: "createDialogAtom",
  default: false,
});
const userProfileAtom = atom({
  key: "userProfileAtom",
  default: false,
});
const currentGameAtom = atom({
  key: "currentGameAtom",
  default: null,
});
const jitsiRoomAtom = atom({
  key: "jitsiRoomAtom",
  default: null,
});
const videoApprovalAtom = atom({
  key: "videoApprovalAtom",
  default: false,
});

export { createDialogAtom, joinDialogAtom, userProfileAtom, currentGameAtom, videoApprovalAtom, jitsiRoomAtom };
