import React from "react";
import "./Dashboard.css";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase";
import { addDoc, collection } from "firebase/firestore";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { createDialogAtom } from "../utils/atoms";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Select,
  MenuItem
} from "@material-ui/core";
import { ActiveGameFormats } from "./GameFormats"

const CreateGameInstance = () => {
  const [user] = useAuthState(auth);
  const [open, setOpen] = useRecoilState(createDialogAtom);
  const [gameInstanceName, setGameInstanceName] = useState("");
  const [gameInstanceFormat, setGameInstanceFormat] = useState("Quiz Cup");
  const handleClose = () => {
    setOpen(false);
  };

  const createGameInstance = async () => {
    try {
      await addDoc(collection(db, "game_instances"), {
        creatorUid: user.uid,
        name: gameInstanceName,
        status: 'registering',
        game_format: gameInstanceFormat,
        creatorName: user.displayName,
        creatorPhoto: user.photoURL,
        game_state: 'paused',
        posts: [],
        participants: [],
        participant_roles: {},
        chat_text: []
      });

      handleClose();
    } catch (err) {
      alert(`Cannot create game - ${err.message}`);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Create New Game</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Name your game!
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Game Name"
            type="text"
            fullWidth
            value={gameInstanceName}
            onChange={(e) => setGameInstanceName(e.target.value)}
          />
          <br />
          <DialogContentText>
            Game Format:
          </DialogContentText>
          <Select
            margin="dense"
            label="Game Format"
            fullWidth
            value={gameInstanceFormat}
            onChange={(e) => setGameInstanceFormat(e.target.value)}>
            { ActiveGameFormats.map((dt, i) => {
                return (
                 <MenuItem label="Select a Game Format"
                           value={dt.name}
                           key={i}
                           name={dt.name}>
                          {dt.name}
               </MenuItem>) }) }
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={createGameInstance} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CreateGameInstance;
