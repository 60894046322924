import MerseysideHarsh from './GameFormats/MerseysideHarsh';
// import MerseysideNice from './GameFormats/MerseysideNice';
import QuizCup from './GameFormats/QuizCup';
import Solo from './GameFormats/Solo';

const GameFormats = [
  {
    name: 'Merseyside Harsh',
    category: 'team',
    description: '2 teams, 8 rounds, 8 questions/round. Each player is in the hot seat once per round. Two points for a direct answer, ' +
                  'or the player can pass and the team captain will then elect a player to answer for 1 point. The hotseat team has ' +
                  '15 seconds. After time-out or a wrong answer the question goes to the other team they have 5 seconds to answer for ' +
                  '1 point. The next question goes to the other team, until all players have gone (in order). After the fourth round ' +
                  '(halftime), the team going first switches to the other team.',
    team_count: 2,
    game_class: MerseysideHarsh,
    default_seat_count: 4,
    status: 'active',
    requires_host: true
  },
  {
    status: 'inactive',
    name: 'Merseyside Nice',
    category: 'team',
    description: '2 teams, 8 rounds, 8 questions/round. Each player is in the hot seat once per round. Two points for a direct answer. ' +
                 'why we call it "nice"). The hotseat teams has 15 seconds total. After time-out or both answers have been wrong, the ' +
                 'If the player misses the free guess, another player on the team can still answer for 1 point (a "free guess", which is ' +
                 'question goes to the other team. The other team ' +
                 'has 5 seconds to answer for 1 point. The next question goes to the other team until all players have gone (in order). ' +
                 'After the fourth round (halftime), the team going first switches to the other team.',
    team_count: 2,
    game_class: MerseysideHarsh,
    requires_host: true
  },
  {
    status: 'active',
    name: 'Quiz Cup',
    category: 'team',
    description: '2 teams of 4 players each, 2 rounds. Players select a question pair from the remaining categories and may answer for ' +
                 'two points. If they miss or pass, the captain can select a player to try to answer for one point. If the team misses ' +
                 'or passes, a player from the other team may answer for a point. After each player has had one category, the remaining ' +
                 'categories are played by the entire team (with discussion) and can be answered for 1 point by the opposition if missed. ' +
                 'Finally the team chooses a mystery box, either A or B, containing 3 questions from among the existing categories. ' +
                 'Each right answer is good for 2 points to the team. The sides then reverse and the same structure is used for round two.',
    team_count: 2,
    default_seat_count: 4,
    game_class: QuizCup,
    requires_host: true
  },
  {
    name: 'Erudite Crudite!',
    category: 'team',
    description: 'Teams compete in a three-way buzzer race. Whichever player buzzes must answer! Long live Mac McGarry.',
    game_class: null,
    team_count: 3,
    status: 'inactive',
    requires_host: true
  },
  {
    name: 'Solo',
    category: 'individual',
    description: '10 random, somewhat crappy questions (for now).',
    game_class: Solo,
    team_count: 0,
    status: 'active',
    requires_host: false
  },
  {
    name: '3-Way Peril',
    category: 'head-to-head',
    description: 'Two rounds of buzzer and wager-based trivia excitement in a live game show format!',
    game_class: Solo,
    team_count: 0,
    status: 'inactive',
    requires_host: true
  },
  {
    name: 'WQC',
    category: 'team',
    description: 'World Quizzing Championship format',
    game_class: MerseysideHarsh,
    team_count: 0,
    status: 'inactive',
    requires_host: true
  }
];

const GameFormatByName = (name) => GameFormats.find(element => element.name === name);
const ActiveGameFormats = GameFormats.filter(element => element.status === 'active');

export { GameFormats, ActiveGameFormats, GameFormatByName };
