import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/Spinner";
import { GameFormatByName } from "./GameFormats"
import { auth, db } from "../firebase";
import { doc, collection, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { JoinGameInstance } from "./JoinGameInstance";
import "./GameRoom.css";
import { useSetRecoilState } from "recoil";
import Home from "./Home";

import { currentGameAtom } from "../utils/atoms";

const GameRoom = ({ gameId, jitsiRoom, chat }) => {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  const [gameInstanceData, setGameInstanceData] = useState();
  const [participantsData, setParticipantsData] = useState(null);
  const setCurrentGame = useSetRecoilState(currentGameAtom);

  const fetchParticipants = async (gameInstanceData) => {
     let output_hash = {};

     let participants = gameInstanceData.data().participants;
     if (participants.length > 0)
     {
       const player_query = query(collection(db, "users"), where("uid", "in", participants));
       let player_set = await getDocs(player_query);

       for (var i = 0; i < player_set.docs.length; i++) {

         let pdata = player_set.docs[i].data();
         output_hash[pdata.uid] = pdata;
       };
     }
     setParticipantsData(output_hash);

  }

  useEffect(() => {
    if (loading || !user) return(<Spinner />);
  }, [user, loading]);

  useEffect(() => {
    return onSnapshot(doc(db, "game_instances", gameId), (result) => {    // onSnapshot -> updates when it changes

        if (result.exists()) {
          setGameInstanceData(result);
          setCurrentGame({ name: result.data().name,
                           game_format: result.data().game_format,
                           id: result.id });
          fetchParticipants(result);
        } else {
          // redirect away from nonexistent game
          navigate("/");
        }
      });
  }, [gameId, navigate, setCurrentGame]);

  if (!user) {
    return (<Home gameId={ gameId }/>);
  }
  else
  if (!participantsData) {
    return(<Spinner />);
  }
  else if (user && gameInstanceData && participantsData)
   {
      const ThisGameClass = GameFormatByName(gameInstanceData.data().game_format)?.game_class;

      // player has the link but is not part of the game (yet)
      if (!participantsData.hasOwnProperty(user.uid)) {
        return(<div id="gameroom">
                  <JoinGameInstance gameInstanceData={ gameInstanceData }/>;
                </div>);
      } else {
        return(<div id="gameroom">
                  <ThisGameClass gameInstanceData={ gameInstanceData } jitsiRoom={ jitsiRoom } participantsData={ participantsData } chat={ chat } />
               </div>);
      }
    }
  else {
    return(<Spinner />);
  }

}

export default GameRoom;
