// bond a game room and a jitsi conference without re-rendering all the video a billion times
import React, { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { JitsiVideoWrapper } from "./JitsiVideoWrapper"
import LeaveGameInstance from "./LeaveGameInstance"
import Spinner from "../components/Spinner";
import { auth } from "../firebase";
import { videoApprovalAtom } from "../utils/atoms";
import "./GameRoomWrapper.css";
import Navbar from "./Navbar";
// import GameRoom from "./GameRoom"
import { Button } from "@material-ui/core";

const GameRoomWrapper = () => {
  const { game_id } = useParams();
//  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);
  const [videoApproved, setVideoApproved] = useRecoilState(videoApprovalAtom);
  const conferenceRoomName = String('strivia-' + game_id.replace(/([A-Z])/g, "_$1")).toLowerCase();
  // console.log('conf room is', conferenceRoomName);

  useEffect(() => {
    if (loading || !user) {
      return (<Spinner />)
    }
  }, [user, loading]);

  if (videoApproved) {
    return (<>
              <Navbar />
              <JitsiVideoWrapper conferenceRoomName={ conferenceRoomName } gameId={ game_id }></JitsiVideoWrapper>
            </>);
  } else {
    return(<div>
              <div className="blur-back" />
              <div className="blur-front">
                <div className="dialog">
                  Audio/Video consent: to play this game you must click Accept, permitting use of your camera and microphone during gameplay.
                  <br />
                  <br />
                  <div style={ { "fontSize": "0.7em" } }>(You can still shut them off during the game,<br />though you should only do so when it is approved by the game host.)</div>
                  <br />
                  <br />
                  <LeaveGameInstance gameId={ game_id } message={ "Reject" } className='big-button-red' confirm={ false }>Cancel</LeaveGameInstance>

                  <Button className='big-button' style={ { "marginLeft": "8em" } } onClick={ () => setVideoApproved(true) }>Accept</Button>
                </div>
              </div>
           </div>);
  }
  // needs to quit

}

export default GameRoomWrapper;
