import { Avatar, IconButton, CircularProgress } from "@material-ui/core";
import { Add, ExitToAppSharp } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { onSnapshot, doc } from "firebase/firestore";
import { auth, db, logout } from "../firebase";
import { createDialogAtom, userProfileAtom, currentGameAtom } from "../utils/atoms";
import CreateGameInstance from "./CreateGameInstance";
import LeaveGameInstance from "./LeaveGameInstance";
import { UserProfile } from "./UserProfile";
import { USE_BETA_JITSI_SERVER } from "./JitsiVideoWrapper";
import "./Navbar.css";

function Navbar() {
  const [user, loading] = useAuthState(auth);
  const setCreateOpened = useSetRecoilState(createDialogAtom);
  const setProfileOpened = useSetRecoilState(userProfileAtom);
  const currentGame = useRecoilValue(currentGameAtom);
  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);

  let game_action, current_game;
  if (currentGame) {
    current_game = (<span>{ currentGame?.name } ({ currentGame?.game_format })</span>);
  }
  game_action = currentGame ? <LeaveGameInstance gameInstanceData={ currentGame } message={ "Leave Game"} confirm={ true } /> :
                <IconButton
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={() => {
                      setCreateOpened(true);
                      }}>
                  <Add /> Start a New Game
                </IconButton>;

  useEffect(() => {
    if (loading) return;
  }, [loading]);

  useEffect(() => {
    const fetchUser = () => {
      console.log('fetching user');
      try {
        const unsub = onSnapshot(doc(db, "users", user.uid), (u_doc) => {
          if (u_doc.exists) {
            setUserData(u_doc);
          }
        });
        return unsub;
      } catch(error) {
        alert('navbar - fetch user:' + error.message);
      }
    }

    if (user) return fetchUser();
  }, [user]);

  useEffect(() => {
    const fetchUserRole = () => {
      console.log('fetching user role');
      try {
        const unsub = onSnapshot(doc(db, "user_roles", user.uid), (r_doc) => {
          setUserRole(r_doc);

          // handle special user roles... i.e. 'banned'
          if (r_doc?.data()?.role === 'banned' || r_doc?.data()?.allowed === false)  {
            console.log('user ban');
            logout();
          }
        });
        return unsub;
      } catch(error) {
        alert('navbar - fetch role:' + error.message);
      }
    }

    if (user) return fetchUserRole();
  }, [user]);

  return (
    <>
      <CreateGameInstance />
      { userData ? (<UserProfile userData={ userData }/>) : '' }
      <nav className="navbar">
        <div className="navbar__left">
          <img
            src="/gammawave_green_man.png"
            alt="Gamma Show Trivia"
            width="200"
            className="navbar__logo"
            style={ { marginRight: '0' }}
          />
          <img src="/logo.png" alt="Gamma Show Trivia!" style={ { height: '2em', marginLeft: '0', marginTop: '3px' }}/>
          live game show trivia,&nbsp;<a className="jitsi-credit" rel="noreferrer" href="https://meet.jit.si" target="_blank" style={ { marginLeft: '0' }}>with video/voice powered by Jitsi{ USE_BETA_JITSI_SERVER ? ' (beta server)' : '' }</a>
        </div>
        <div className="navbar__center">
          <span>{ current_game }</span>
        </div>
        <div className="navbar__right">
          { game_action }
          <IconButton onClick={() => { setProfileOpened(true) }}>
            <Avatar src={user?.photoURL} /> &nbsp; { userData?.data()?.nickname ? userData?.data()?.nickname : <CircularProgress /> } { userRole?.data()?.role ? `(${userRole?.data()?.role})` : '' }
          </IconButton>
          <IconButton onClick={logout}>
            <ExitToAppSharp />
            Log Out
          </IconButton>
        </div>
      </nav>
    </>
  );
}
export default Navbar;
