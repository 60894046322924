import React, { useEffect } from 'react';

import { signInWithGoogle, auth } from '../firebase.js'
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

const Home = ({ gameId }) => {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate()

  useEffect(() => {

    if (loading) return;
    if (user && gameId !== undefined) {
      navigate(`/gameroom/${gameId}`)
    } else if (user) {
      navigate('/dashboard');
    }
  }, [loading, user, gameId, navigate]);

return(
  <div className="home">
    <div className="home__container">

      <img
        src="/logo.png"
        alt="Gamma Show Trivia"
        className="home__image"
        width="500"
        style={ { backgroundColor: '#f5f5f5', margin: '0px auto 25px' }}
      />
      <img
        src="/gammawave_green_man.png"
        alt="Gamma Show Trivia"
        className="home__image"
        width="600"
        style={ { backgroundColor: 'rgb(10,60,130)' }}
      />
      <button className="home__login" onClick={signInWithGoogle}>
        Login with Google
      </button>
    </div>
  </div>
  )
};

export default Home;
