import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { JitsiVideoWrapper, VideoSlot } from "../JitsiVideoWrapper"
import Spinner from "../Spinner";

import { Markup } from 'interweave';

/*
// import './mic.css';
// import trivia from './trivia.json';
// import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
// import microPhoneIcon from "./microphone.svg";
*/

// import { moment } from "react-moment";
import { auth } from "../../firebase";

const Spectators = ({ gameInstanceData, participantsData }) => {
  const [user] = useAuthState(auth);
  let slots = [];

  let spectators = gameInstanceData.data().spectators;

  for (var i = 0; i < spectators.length; i++)
  {
    let spectator = spectators[i];
    slots.push(<div key={ 'spectator-' + i }>
                <div className="spectator-slot" id={ "spectator-" + spectator }>
                  <div className='spectator-placement' id={ 'stream-player-wrapper-' + spectator }>
                    <VideoSlot id={ spectator }></VideoSlot>
                    <div className="noiseWrapper">
                      <div className="noise">
                      </div>
                    </div>
                  </div>
                </div>
              <div className="spectator_label">{ spectator === user.uid ? "(you) " : ''}{ participantsData[spectator]?.nickname }</div>
            </div>)
  }

  return (
    <div id="spectators">
      <div className="spectator_header">SPECTATORS</div>
      <div className="spectator_column">
        { slots }
      </div>
    </div>
  )
}

const ScoreBoard = ({ gameInstanceData, player, children }) => {
  return (<div className='scoreboard' id={ 'scoreboard-player' }>
  {children}
  </div>)
};

const Contestants = ({ gameInstanceData, participantsData }) => {
  let podiums = [];
  const [user] = useAuthState(auth);
  let players = gameInstanceData.data().players;

  for (var i = 0; i < players.length; i++)
  {
    let player = players[i];
    podiums.push(<div key={ 'contestant-' + i } className="contestant-podium" id={ "contestant-" + player }>
                    <div className='contestant-placement' id={ 'stream-player-wrapper-' + player }>
                      <VideoSlot id={ player }></VideoSlot>
                      <div className="noiseWrapper">
                        <div className="noise">
                        </div>
                      </div>
                    </div>
                    <ScoreBoard gameInstanceData={ gameInstanceData} player={ player }>
                      <div className="player_name">{ participantsData[player]?.nickname }{ player === user.uid ? ' (you)' : '' }</div>
                      <div className="score_text">${ 0 }</div>
                    </ScoreBoard>
                  </div>)
  }

  return (
    <div id="contestants">
      { podiums }
    </div>
  );
};

const RobotHost = () => {
  return (
    <div id="robot_wrapper">
      <div id="robot_host"></div>
      <div id="robot_marquee">AXEL TROBOTT</div>
    </div>
  )
}

const Question = () => {
  const [questionData, setQuestionData] = useState(null);
  const [newQuestionNeeded, setNewQuestionNeeded] = useState(true);
  const shuffler = array => array.sort(() => 0.5 - Math.random());

  const checkAnswer = (correct) => {
    if (correct) {
      alert('That is the correct answer!!')
    } else {
      alert('You suck!')
    }
    setNewQuestionNeeded(true);
  }

  useEffect(() => {
    const fetchQuestion = () => {
      console.log('fetching');
      fetch('https://opentdb.com/api.php?amount=1&type=multiple')
        .then((response) => { return response.json() })
        .then((data) => {
          let qq = data.results[0];
          let answers = qq['incorrect_answers'].concat(qq['correct_answer']);
          qq.shuffled_answers = shuffler(answers);

          setQuestionData(qq);
          setNewQuestionNeeded(false);
        })
    };

    if (newQuestionNeeded) {
      return fetchQuestion();
    }
    else {
      return (<Spinner />);
    }
  }, [newQuestionNeeded]);

  if (questionData === null) {
    return (<Spinner />);
  }  else {

      let boxes = [];
      for (var i = 0; i < questionData.shuffled_answers.length; i++) {
        let is_correct = questionData.shuffled_answers[i] === questionData['correct_answer'];
        if (is_correct) { console.log('answer', is_correct, i)  };
        boxes.push(<div key={ 'question_answer-' + i } className={ 'answer answer-correct-' + is_correct } onClick={ () => checkAnswer(is_correct) }>
                     <Markup content={ questionData.shuffled_answers[i] } />
                   </div>);
      }
      return (<div id="question_box">
                <Markup content={ questionData['question'] } />
                <div id="answer_box_wrapper">
                  <div id="answer_boxes">
                    { boxes }
                  </div>
                </div>
              </div>)
  };
}

const GameBoard = ({ gameInstanceData, participantsData }) => {
  return (<div className='gameboard'>
            <RobotHost />
            <Question />
            <Spectators gameInstanceData={ gameInstanceData } participantsData={ participantsData }/>
            <Contestants gameInstanceData={ gameInstanceData } participantsData={ participantsData }/>
          </div>);
};

const Solo = ({ gameInstanceData, participantsData }) => {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (!user) navigate("/");
  }, [loading, user, navigate]);

  if (loading) {
    return(<Spinner />);
  } else {
    return (
      <div className="gameroom">
        <JitsiVideoWrapper gameInstanceData={ gameInstanceData }
          children={(<GameBoard gameInstanceData={ gameInstanceData } participantsData={ participantsData } />)}>
        </JitsiVideoWrapper>
      </div>
    );
  }
}
export default Solo;
