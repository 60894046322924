import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Dashboard from "./components/Dashboard"
import Navbar from "./components/Navbar"
import GameRoomWrapper from "./components/GameRoomWrapper"
import { RecoilRoot } from "recoil"
import '@fontsource/roboto';

function App() {
  return (
    <div className="App">
      <RecoilRoot>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Home />}></Route>
            <Route path="/dashboard" element={<div>
                                                <Dashboard />
                                              </div>}></Route>
            <Route exact path="/gameroom/:game_id" element={<div>
                                                <GameRoomWrapper />
                                              </div>}></Route>
            <Route path="*" element={<div className="box404">
                              <div>404</div>
                            </div>} />
          </Routes>
        </BrowserRouter>
      </RecoilRoot>
    </div>
  );
}

export default App;
