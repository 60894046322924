import React, { useEffect, useLayoutEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { VideoSlot } from "../JitsiVideoWrapper"
import { IconButton } from "@material-ui/core";
import './AllFormats.css';
import Timer from '../Timer';
import Draggable from "../Draggable";
import Shutter from "../Shutter";

import { updateDoc } from "firebase/firestore";

// TODO: temporary
import QuizCupDummyQuiz from '../../testing/QuizCupDummyQuiz'

import { auth } from "../../firebase";

const Spectators = ({ gameInstanceData, participantsData, jitsiRoom, selection }) => {
  const [user] = useAuthState(auth);
  const [animation, setAnimation] = useState(-1);

  useLayoutEffect(() => {
    if (animation !== -1) {
      setAnimation(1);
    }
    setTimeout(() => { setAnimation(0) }, 1100);
  }, [selection.length]);

  let slots = [];
  for (var i = 0; i < selection.length; i++)
  {
    let spectator = selection[i];
    slots.push(<div key={ 'spectator-' + i }>
                 <div className="spectator-slot" id={ "spectator-" + spectator }>
                   <div className='spectator-placement' id={ 'stream-player-wrapper-' + spectator }>
                     <VideoSlot participantId={ spectator } jitsiRoom={ jitsiRoom }></VideoSlot>
                   </div>
                   <div className="spectator_label">{ spectator === user.uid ? "(you) " : ''}{ participantsData[spectator]?.nickname }</div>
                 </div>
               </div>)
  }

  return (
    <Draggable x={ "90%" } y={ "45px" }>
      <Shutter name={ `Spectators (${selection.length})` } animation={ animation } startHidden="true">
        <div id="spectators">
          <div className="spectator_column">
            { slots }
          </div>
        </div>
      </Shutter>
    </Draggable>
  )
}

const QuizCupScoreBoard = ({ gameInstanceData, player, children, dummy }) => {
  return (<div className='scoreboard' id={ 'scoreboard-player-' + player }>
  {children}
  </div>)
};

/*
const Controls = () => {
  return (<div className='controls'>

          </div>)
} */

const increment_score = (count, gameInstanceData, player, teamId, mates) => {
    try {
      let gdata = gameInstanceData.data();

      let teamScore = 0;
      for (var i = 0; i < mates.length; i++)
      {
          if (mates[i] !== player) {
            teamScore += (gdata.player_scores[mates[i]] || { 'total': 0 })['total'];
          }
      }
      if (gdata.player_scores['team ' + teamId]) {
        teamScore += 0; // (0 + gdata.player_scores['team ' + teamId]);
      }

      let tempCurTeamScore = gdata['team_scores'] || [0,0,0];

      let tempCurPlayerScore = gdata['player_scores'] || {};
      if (!tempCurPlayerScore[player]) {
        tempCurPlayerScore[player] = { 1: 0, 2: 0, total: 0 };
      }

      // inc. 2 pointer count
      tempCurPlayerScore[player][Math.abs(count)] += (count > 0 ? 1 : -1);
      if (tempCurPlayerScore[player][Math.abs(count)] < 0)
      {
        tempCurPlayerScore[player][Math.abs(count)] = 0;
      }

      tempCurPlayerScore[player]['total'] = (tempCurPlayerScore[player][1] + 2 * tempCurPlayerScore[player][2]);
      tempCurTeamScore[teamId] = teamScore + tempCurPlayerScore[player]['total'];

      // RESET
      // tempCurPlayerScore[player] = { 1: 0, 2: 0, total: 0 };
      // tempCurTeamScore[teamId] = 0;

      let new_data = {
        team_scores: tempCurTeamScore,
        player_scores: tempCurPlayerScore
      }
      updateDoc(gameInstanceData.ref, new_data);
    } catch (e) {
      alert(e);
    }
}

// TODO: these buttons shouldn't be here. Just a +2, a +1 for each other player, and a "WRONG" button; then flip, with +1 for all opponents.
// Game position should be in a stack that can be undone (and perhaps even re-done, until changed)
const ScoreControls = ({ game, participantsData, player, teamId, mates, chat, dummy }) => {
  let nick = participantsData[player]?.nickname || ('team ' + teamId);
  return (<div className="score-buttons">
            <div className="good-score">
              { dummy ? null :
                <button onClick={ () => {
                                          increment_score(2, game, player, teamId, mates)
                                          chat.sendMessage("RefBot||||" + nick + " was awarded 2 points");
                                        }}>+2</button> }
              <button onClick={ () => {
                                        increment_score(1, game, player, teamId, mates)
                                        chat.sendMessage("RefBot||||" + nick + " was awarded 1 point");
                                      }}>+1</button>
            </div>
            <div className="bad-score">
              <button disabled={ player_scores(game, player)[teamId]['total'] <= 0 } onClick={ () => {
                   increment_score(-1, game, player, teamId, mates)
                   chat.sendMessage("RefBot||||Score correction; 1 point removed from " + nick) }}>-1</button>
              { dummy ? null :
                <button disabled={ player_scores(game, player)[teamId][2] <= 0 } onClick={ () => {
                  increment_score(-2, game, player, teamId, mates)
                  chat.sendMessage("RefBot||||Score correction; 2 points removed from " + nick) }}>-2</button> }
            </div>
          </div>)
}

const QuizCupContestants = ({ gameInstanceData, participantsData, jitsiRoom, selection, teamId, host, chat }) => {
  let podiums = [];
  const [user] = useAuthState(auth);

  var expected_seats = 4;

  for (var i = 0; i < expected_seats; i++)
  {
    let player = selection[i];
    if (player) {
      let pscores = player_scores(gameInstanceData, player)
      podiums.push(<div key={ 'contestant-' + i } className="merseyside-podium" id={ `team-${teamId}-player-${i}` }>
                      <div className='merseyside-team-placement' id={ 'stream-player-wrapper-' + player }>
                        <VideoSlot participantId={ player } jitsiRoom={ jitsiRoom }></VideoSlot>
                      </div>
                      <QuizCupScoreBoard gameInstanceData={ gameInstanceData } player={ player }>
                        <div className="placard">
                          { participantsData[player]?.nickname }{ player === user.uid ? ' (you)' : '' } <b>&nbsp;/&nbsp;{ pscores.total }&nbsp;</b> ({ pscores[2] }|{ pscores[1] })
                        </div>
                      </QuizCupScoreBoard>
                      { host === user.uid ? (<ScoreControls game={ gameInstanceData } participantsData={ participantsData } player={ player } teamId={ teamId } mates={ selection } chat={ chat }/>) : ''}
                    </div>)
    } else {
      player = `team ${teamId}`;
      let pscores = player_scores(gameInstanceData, player)
      let rot = ((2 * i) + (teamId - 1)) * (180 / expected_seats);
      podiums.push(<div key={ 'contestant-' + i } className="merseyside-podium" id={ `team-${teamId}-player-${i}` }>
                      <div className='merseyside-team-placement' id={ 'stream-player-wrapper-' + player }>
                        <img className="empty-seat-graphic" style={ { filter: `hue-rotate(${rot}deg) saturate(50%)` } } src="/sockmonkey-tp.png" />
                      </div>
                      <QuizCupScoreBoard gameInstanceData={ gameInstanceData } player={ player } dummy={ true }>
                        <div className="placard">
                          Empty Seat <b>&nbsp;</b> (+{ pscores[1] } team pts)
                        </div>
                      </QuizCupScoreBoard>
                      { host === user.uid ? (<ScoreControls game={ gameInstanceData } participantsData={ participantsData } player={ player } teamId={ teamId } mates={ selection } chat={ chat } dummy={ true }/>) : ''}
                    </div>)
    }
  }

  return (
    <div className="merseyside-team" id={ 'team-' + teamId }>
      <div className='team-header' id={ `team-header-${teamId}` }>{ teamId === 1 ? 'HOME' : 'AWAY' }
        <div className="led-scoreboard" id={ `team-${teamId}-score` }>{ padded(team_scores(gameInstanceData)[teamId], 3) }</div>
      </div>
      <div className="merseyside-flexwrap">
        { podiums.length > 0 ? podiums : (<div className="placard placard-narrow">No players have joined this team yet</div>) }
      </div>
    </div>
  );
};

const HostControls = ({ gameInstanceData, player }) => {
  // <div className="placard">Host Control Panel</div>
  return (<div className="merseyside-host-control-panel">
          </div>);
}

const padded = (text, size) => {
    var s = "000000" + text;
    return s.substr(s.length-size);
}

const team_scores = (gameInstanceData) => {
  return (gameInstanceData.data()['team_scores'] || [0,0,0]);
}

const player_scores = (gameInstanceData, player) => {
  if (gameInstanceData.data().hasOwnProperty('player_scores') && gameInstanceData.data().player_scores.hasOwnProperty(player))
    return gameInstanceData.data().player_scores[player];
  return { 1: 0, 2: 0, total: 0 };
}

const TeamScoreboards = ({ gameInstanceData }) => {
  return (<div className='central-info'>
          </div>);
}

function clone(hash) {
  var newObj = {};
  if (hash !== undefined) {
    Object.keys(hash).forEach(function(key) {
      newObj[ key ] = hash[ key ];
    });
  }

  return newObj;
}


// display box for categories, questions, and answers
const DisplayBox = ({ participantsData, host, gameInstanceData }) => {
  const [user] = useAuthState(auth);
  const default_frame_state = { state: 'show_round_identifier',
                                category: null,
                                host_instructions: '',
                                round: 0,
                                question: 0,

                                step_in_question: 0,
                                teams_turn: 0,
                                player_by_seat: 0,

                                qc_mode: 'players',
                                turn_total: 0,
                                played_categories: [],
                                action_stack: [] };

  const update_frame_info = async (new_state) => {
    try {
      await updateDoc(gameInstanceData.ref, { display_current: new_state });
    } catch (e) {
      alert(e);
    }
  };

  let display_current = gameInstanceData.data().display_current;

  if (!display_current) {
    update_frame_info(default_frame_state);
    display_current = default_frame_state;
  }

  const advance_frame = () => {
    const new_state = clone(display_current);

//    new_state.action_stack.push(display_current);

    if (new_state.turn_total === all_questions_in_round(QuizCupDummyQuiz, new_state.round).length) {
      new_state.round += 1;
      if (new_state.round === 2) {
        new_state.state = 'game_over';
      } else {
        new_state.turn_total = 0;
        new_state.question = 0;
        new_state.step_in_question = 0;
        new_state.teams_turn = 1 - new_state.teams_turn;
        new_state.state = 'show_round_identifier';
        new_state.played_categories = [];
      }
    } else if (new_state.state === 'show_round_identifier') {
      new_state.state = 'show_categories';
      new_state.question = 0;
    } else if (new_state.state === 'show_category' && new_state.category) {
      new_state.state = 'show_question';
    } else if (new_state.state === 'show_question' && new_state.category) {
      new_state.state = 'show_answer';
    } else if (new_state.state === 'show_answer' && new_state.category) {
      new_state.question += 1;
      new_state.turn_total += 1;
      if (new_state.question >= matching_items(QuizCupDummyQuiz, new_state.category, new_state.round, new_state.question).length)
      {
        new_state.state = 'show_categories';
        new_state.question = 0;
      } else {
        new_state.state = 'show_category';
      }
    }
    update_frame_info(new_state);
  };

  const reset = () => {
    if (window.confirm("Are you serious?")) {
      update_frame_info(default_frame_state);
    }
  }

  /* const undo = () => {
    // display_current = display_current.action_stack.pop();
  } */

  const can_advance = display_current.state !== 'show_categories' || display_current.category !== null;
  // <IconButton className="next-button" onClick={ undo }>Undo</IconButton>
  const user_is_host = user.uid === host;
  const host_ctrls = (user_is_host ? (<div>
                                          <IconButton className="next-button" onClick={ reset }>RESET</IconButton>
                                          <IconButton disabled={ !can_advance } className="next-button" onClick={ advance_frame }>Next</IconButton>
                                        </div>) : '');

  function get_categories(this_quiz, round) {
    if (round >= 2) {
      round = 0;
    }
    var categories = [];
    var this_set = this_quiz.quiz[round].round;
    for (var i = 0; i < this_set.length; i++) {
      if (categories.indexOf(this_set[i].c) === -1) {
        categories.push(this_set[i].c);
      }
    }
    return categories;
  }

  function select_category(cat) {
    // check if this is mystery box and if it can be selected yet
    // check if the correct player is selecting a category
    const display_current = clone(gameInstanceData.data().display_current || default_frame_state);

    display_current.category = cat;
    display_current.state = 'show_category';
    display_current.played_categories.push(cat);
    update_frame_info(display_current);
  }

  function all_questions_in_round(this_quiz, round) {
    if (round >= 2) {
      round = 0;
    }
    var this_set = this_quiz.quiz[round].round;
    return this_set;
  }

  function matching_items(this_quiz, category, round, question) {
    if (round >= 2) {
      round = 0;
    }
    var this_set = this_quiz.quiz[round].round;
    var these_qs = [];
    for (var i = 0; i < this_set.length; i++) {
      if (this_set[i].c === category) {
        these_qs.push(this_set[i]);
      }
    }
    return these_qs;
  }

  function get_question(this_quiz, category, round, question) {
    var qs = matching_items(this_quiz, category, round, question);
    return qs[question].q;
  }

  function get_answer(this_quiz, category, round, question) {
    var qs = matching_items(this_quiz, category, round, question);
    return qs[question].a;
  }

  let frame_to_show = null;

  let categories = get_categories(QuizCupDummyQuiz, display_current.round);
  if (display_current.state === 'show_round_identifier') {
      frame_to_show = (<div className="qc-text-only">
                          Round { display_current.round + 1 }
                       </div>);
    } else if (display_current.state === 'show_categories') {
      if (display_current.played_categories.length === categories.length) {
        frame_to_show = (<div className="qc-text-only category">
                            End of Round { display_current.round + 1 }
                          </div>);
      } else {
        frame_to_show = (<div className="qc-categories">
                            { categories.map((cat) => {
                              let played = display_current.played_categories.indexOf(cat) !== -1;
                              return (<IconButton key={ `${cat}` }
                                                  className={ (played ? "cat-box played" : "cat-box") }
                                                  disabled={ played }
                                                  onClick={ () => select_category(cat) }>{ cat }</IconButton>)
                            }) }
                         </div>);
      }
    } else if (display_current.state === 'show_category') {
      if (user_is_host) {
        let host_only_text = get_question(QuizCupDummyQuiz, display_current.category, display_current.round, display_current.question)
        frame_to_show = (<div className="qc-text-only qc-host-notes">
                            <div className="category-small">{ display_current.category + ' #' + (display_current.question + 1) }</div>
                            <div className="question-preview">
                              <div className="host-notes">This text is currently visible only to you:</div>
                              { host_only_text }
                            </div>
                         </div>);
      } else {
        frame_to_show = (<div className="qc-text-only category">
                            { display_current.category + ' #' + (display_current.question + 1) }
                         </div>);
      }

    } else if (display_current.state === 'show_question') {
      if (user_is_host) {
        let host_only_text = get_answer(QuizCupDummyQuiz, display_current.category, display_current.round, display_current.question)
        frame_to_show = (<div className="qc-text-only qc-host-notes">
                            { get_question(QuizCupDummyQuiz, display_current.category, display_current.round, display_current.question) }
                            <div className="question-preview">
                              <div className="host-notes">This text is currently visible only to you:</div>
                              { host_only_text }
                            </div>
                         </div>);
      } else {
        frame_to_show = (<div className="qc-text-only">
                            { get_question(QuizCupDummyQuiz, display_current.category, display_current.round, display_current.question) }
                         </div>);
      }
    } else if (display_current.state === 'show_answer') {
      frame_to_show = (<div className="qc-text-only">
                          { get_answer(QuizCupDummyQuiz, display_current.category, display_current.round, display_current.question) }
                       </div>);
    } else if (display_current.state === 'game_over') {
      frame_to_show = (<div className="qc-text-only category">
                         GAME OVER
                       </div>);
    }


  return (<div className="qc-display-box">
            { host_ctrls }
            { frame_to_show }
          </div>);

}

const Chatbox = ({ participantsData, chat }) => {
  const [user] = useAuthState(auth);
  const [chatText, setChatText] = useState("");
  const [animation, setAnimation] = useState(-1);

  const sendChat = () => {
    chat.sendMessage(participantsData[user.uid]?.nickname + "||||" + chatText);
    setChatText("");
  }

  const chat_bottom_ref = React.createRef();
  const submit_button_ref = React.createRef();

  useEffect(() => {
    chat_bottom_ref.current.scrollIntoView({ behavior: 'smooth' });
  }, [chat_bottom_ref, chat.messages]);

  useLayoutEffect(() => {
    if (animation !== -1) {
      setAnimation(1);
    }
    setTimeout(() => { setAnimation(0) }, 1100);
  }, [chat.messages]);

  const handleKeys = (e) => {
    if (e.which === 13 && !e.shiftKey) {
        e.preventDefault();

        sendChat();
    }
  };

  return (<Draggable x={ "1%" } y={ "72%" }>
            <Shutter name={ `Messages (${chat.messages.length})` } animation={ animation }>
            <div className={ `chatbox-wrapper` }>
              <div className="chatbox-messages">
                { chat.messages.map((msg, idx) => {
                   let parts = msg.split("||||", 2);
                   let speaker = parts[0];
                   let message = parts[1];
                   return (<p key={ `msg-${idx}` } className="chatbox-message"><b>{ speaker }:</b> { message }</p>);
                }) }
                <div ref={ chat_bottom_ref } className="chatbox-bottom" />
              </div>
              <div className="chatbox-input-row">
                <textarea onKeyPress={ handleKeys } className="chatbox-input" value={ chatText } onChange={ (e) => { setChatText(e.target.value) } } placeholder="send a message to everyone..." />
                <button ref={ submit_button_ref } className="chatbox-send" onClick={ sendChat }>Send</button>
              </div>
            </div>
          </Shutter>
        </Draggable>);
}

const QuizCupHost = ({ gameInstanceData, participantsData, jitsiRoom, player, chat }) => {
    const [user] = useAuthState(auth);

    // let controls = (player == user.uid ? (<b>CONTROL PANEL</b>) : '');
    if (player) {
      return(<div className="merseyside-host" id="host">
                <Timer gameId={ gameInstanceData.id } isHost={ user.uid === player } />
                <div className='merseyside-host-podium' id={ 'host-podium' }>
                  <div className='merseyside-host-placement' id={ 'stream-player-wrapper-' + player }>
                    <VideoSlot participantId={ player } jitsiRoom={ jitsiRoom }></VideoSlot>
                    <div className="placard">{ participantsData[player]?.nickname }{ player === user.uid ? ' (you)' : ' (HOST)' }</div>
                 </div>
               </div>
               <TeamScoreboards gameInstanceData={ gameInstanceData }/>
               <br />
              { user.uid === player ? (<HostControls gameInstanceData={ gameInstanceData } id={ player } />) : ''}

             </div>)
    } else {
      return(<div className="merseyside-host" id="host">
        <div className="placard">The host isn't here yet.</div>
      </div>)
    }
};

/* const Question = () => {
  const [questionData, setQuestionData] = useState(null);
  const [questionLoading, setQuestionLoading] = useState(true);
  const [newQuestionNeeded, setNewQuestionNeeded] = useState(true);
  const shuffler = array => array.sort(() => 0.5 - Math.random());

  return (<div id="question_box">
            <Markup content={ questionData['question'] } />
          </div>)
} */

const QuizCup = ({ gameInstanceData, participantsData, jitsiRoom, chat }) => {
  let gdata = gameInstanceData.data();
  const spectators = gdata.participants.filter(participant => gdata.participant_roles[participant] === 'spectators');
  const team1 =  gdata.participants.filter(participant => gdata.participant_roles[participant] === 'team 1');
  const team2 =  gdata.participants.filter(participant => gdata.participant_roles[participant] === 'team 2');
  const host =  gdata.participants.filter(participant => gdata.participant_roles[participant] === 'host')[0];
  return (<div className='merseyside-gameboard'>
            <Spectators gameInstanceData={ gameInstanceData } jitsiRoom={ jitsiRoom } participantsData={ participantsData } selection={ spectators } />
            <QuizCupContestants gameInstanceData={ gameInstanceData } participantsData={ participantsData } jitsiRoom={ jitsiRoom } selection={ team1 } teamId={ 1 } host={ host } chat={ chat } />
            <QuizCupHost gameInstanceData={ gameInstanceData } participantsData={ participantsData } jitsiRoom={ jitsiRoom } player={ host } chat={ chat }/>
            <QuizCupContestants gameInstanceData={ gameInstanceData } participantsData={ participantsData } jitsiRoom={ jitsiRoom } selection={ team2 } teamId={ 2 } host={ host } chat={ chat }/>
            <Chatbox participantsData={ participantsData } chat={ chat }/>
            <DisplayBox participantsData={ participantsData } host={ host } gameInstanceData={ gameInstanceData }/>
          </div>);
};

export default QuizCup;
