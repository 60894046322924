import React from "react";
import "./Dashboard.css";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import { updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { userProfileAtom } from "../utils/atoms";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  TextareaAutosize
} from "@material-ui/core";
import Ology from "../misc/Ology.js";

const random_about = (name) => {
  var i = Math.floor(Math.random() * Ology.length);
  var n = Math.floor(Math.random() * 996) + 2;
  return `${name} is the author of over ${n} works of nonfiction in the field of ${Ology[i].toLowerCase()}.`;
}

const UserProfile = ({ userData }) => {
  const [user] = useAuthState(auth);
  const [open, setOpen] = useRecoilState(userProfileAtom);
  const [nickname, setNickname] = useState();
  const [about, setAbout] = useState();
  const [willTeam, setWillTeam] = useState();
  const [willHost, setWillHost] = useState();
  const [willWrite, setWillWrite] = useState();
  const [emailable, setEmailable] = useState();
  const [notifiable, setNotifiable] = useState();
  const [willBeInvolved, setWillBeInvolved] = useState();

  const handleClose = () => {
    setOpen(false);
  };

  const updateUserProfile = () => {
    try {

      // TODO: these validations need to be on the database side too... :-/
      if (nickname === null || nickname.length < 5)
      {
        alert("Nickname must be at least 5 characters")
      }
      else if (nickname.length > 30)
      {
        alert("Maximum nickname length is 30 characters")
      }
      else if (about.length > 250)
      {
        alert("Maximum 'about' length is 250 characters. We don't need your whole life story.")
      }
      else {
        // todo: uniqueness test
        updateDoc(userData.ref, {
                  nickname: nickname,
                  about: about,
                  willTeam: willTeam,
                  willWrite: willWrite,
                  willHost: willHost,
                  notifiable: notifiable,
                  emailable: emailable,
                  involved: willBeInvolved
        });

        handleClose();
      }
    } catch (err) {
      alert(`Cannot update user - ${err.message}`);
    }
  };

  useEffect(() => {
    let udata = userData?.data();
    console.log(udata);

    setNickname(udata?.nickname || user?.displayName);
    setAbout(udata?.about || random_about(user?.displayName));
    setEmailable(!!udata?.emailable);
    setNotifiable(!!udata?.notifiable);
    setWillBeInvolved(!!udata?.involved)
    setWillHost(!!udata?.willHost);
    setWillWrite(!!udata?.willWrite);
    setWillTeam(!!udata?.willTeam);
  }, [userData, user]);

  return (
    <div>
      <Dialog
        fullWidth
        margin="dense"
        open={open}
        onClose={handleClose}
        className="top-dialog"
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Your Profile</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Your Unique Nickname"
            type="text"
            fullWidth
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
          />
        </DialogContent>

        <DialogContent>
          <DialogContentText>
            About you (250 char max):
          </DialogContentText>
          <TextareaAutosize
            className="about-me"
            label="about"
            type="text"
            maxLength="250"
            value={about}
            onChange={(e) => setAbout(e.target.value)}
          />
        </DialogContent>

        <DialogContent>
          <FormGroup>
            <FormControlLabel control={<Checkbox
                                          label="involved"
                                          checked={willTeam}
                                          onChange={(e) => setWillTeam(e.target.checked)} />} label="I'm interested in joining a team or teams" />
          </FormGroup>
        </DialogContent>

        <DialogContent>
          <FormGroup>
            <FormControlLabel control={<Checkbox
                                          label="will host"
                                          checked={willHost}
                                          onChange={(e) => setWillHost(e.target.checked)} />} label="I'm available for hosting games (no commitment required)" />
          </FormGroup>
        </DialogContent>

        <DialogContent>
          <FormGroup>
            <FormControlLabel control={<Checkbox
                                          label="will write"
                                          checked={willWrite}
                                          onChange={(e) => setWillWrite(e.target.checked)} />} label="I'm interested in writing questions or submitting quizzes (no commitment required)" />
          </FormGroup>
        </DialogContent>

        <DialogContent>
          <FormGroup>
            <FormControlLabel control={<Checkbox
                                          label="notifiable"
                                          checked={notifiable}
                                          onChange={(e) => setNotifiable(e.target.checked)} />} label="E-mail me with notifications about my games" />
          </FormGroup>
        </DialogContent>

        <DialogContent>
          <FormGroup>
            <FormControlLabel control={<Checkbox
                                          label="emailable"
                                          checked={emailable}
                                          onChange={(e) => setEmailable(e.target.checked)} />} label="E-mail me with updates about Shout Trivia (or whatever it is called)" />
          </FormGroup>
        </DialogContent>

        <DialogContent>
          <FormGroup>
            <FormControlLabel control={<Checkbox
                                          label="involved"
                                          checked={willBeInvolved}
                                          onChange={(e) => setWillBeInvolved(e.target.checked)} />} label="I'm interested in generally being involved in as-yet unknown ways" />
          </FormGroup>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={updateUserProfile} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export { UserProfile };
