const Ology = ["Acarology",
"Actinobiology",
"Actinology",
"Aerobiology",
"Aerology",
"Aetiology",
"Agrobiology",
"Agrology",
"Agrostology",
"Algology",
"Allergology",
"Andrology",
"Anesthesiology",
"Angiology",
"Anthropology",
"Apiology",
"Arachnology",
"Archaeology",
"Archaeozoology",
"Areology",
"Astacology",
"Astrobiology",
"Astrogeology",
"Audiology",
"Autecology",
"Bacteriology",
"Bioecology",
"Biology",
"Bromatology",
"Cardiology",
"Cariology",
"Cetology",
"Climatology",
"Coleopterology",
"Conchology",
"Coniology",
"Craniology",
"Criminology",
"Cryology",
"Cynology",
"Cytology",
"Cytomorphology",
"Cytopathology",
"Dendrochronology",
"Dendrology",
"Dermatology",
"Dermatopathology",
"Desmology",
"Diabetology",
"Dipterology",
"Ecohydrology",
"Ecology",
"Ecophysiology",
"Edaphology",
"Electrophysiology",
"Embryology",
"Endocrinology",
"Entomology",
"Enzymology",
"Epidemiology",
"Ethology",
"Exobiology",
"Exogeology",
"Felinology",
"Foetology",
"Formicology",
"Gastrology",
"Gemology",
"Geobiology",
"Geochronology",
"Geology",
"Geomorphology",
"Gerontology",
"Glaciology",
"Gynecology",
"Hematology",
"Heliology",
"Helioseismology",
"Helminthology",
"Hepatology",
"Herbology",
"Herpetology",
"Heteroptology",
"Hippology",
"Histology",
"Histopathology",
"Hydrogeology",
"Hydrology",
"Ichnology",
"Ichthyology",
"Immunology",
"Jujitsu", // this is here because there is otherwise no J
"Karyology",
"Kinesiology",
"Kymatology",
"Laryngology",
"Lepidopterology",
"Limnology",
"Lithology",
"Lymphology",
"Malacology",
"Mammalogy",
"Meteorology",
"Methodology",
"Metrology",
"Microbiology",
"Micrology",
"Mineralogy",
"Mycology",
"Myology",
"Myrmecology",
"Nanotechnology",
"Nanotribology",
"Nematology",
"Neonatology",
"Nephology",
"Nephrology",
"Neurology",
"Neuropathology",
"Neurophysiology",
"Nosology",
"Oceanology",
"Odonatology",
"Odontology",
"Oncology",
"Oology",
"Ophthalmology",
"Ornithology",
"Orology",
"Orthopterology",
"Osteology",
"Otolaryngology",
"Otology",
"Otorhinolaryngology",
"Paleoanthropology",
"Paleobiology",
"Paleobotany",
"Paleoclimatology",
"Paleoecology",
"Paleontology",
"Paleophytology",
"Paleozoology",
"Palynology",
"Parapsychology",
"Parasitology",
"Pathology",
"Petrology",
"Pharmacology",
"Phenology",
"Phlebology",
"Phonology",
"Phycology",
"Physiology",
"Phytology",
"Phytopathology",
"Phytosociology",
"Planetology",
"Planktology",
"Pomology",
"Posology",
"Primatology",
"Proctology",
"Psychobiology",
"Psychology",
"Psychopathology",
"Psychopharmacology",
"Psychophysiology",
"Pulmonology",
"Radiology",
"Reflexology",
"Rheology",
"Rheumatology",
"Rhinology",
"Sarcology",
"Scatology",
"Sedimentology",
"Seismology",
"Selenology",
"Serology",
"Sitiology",
"Sociobiology",
"Sociology",
"Somatology",
"Somnology",
"Speleology",
"Stomatology",
"Symptomatology",
"Synecology",
"Technology",
"Thermology",
"Tocology",
"Topology",
"Toxicology",
"Traumatology",
"Tribology",
"Trichology",
"Typology",
"Urology",
"Vaccinology",
"Virology",
"Volcanology",
"Windshield Wiper Repair", // another one missing
"Xenobiology",
"Xylology",
"Zooarchaeology",
"Zoology",
"Zoopathology",
"Zoopsychology",
"Zymology"];

export default Ology;
