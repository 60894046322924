import React, { useEffect } from "react";
import "./Dashboard.css";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, logout } from "../firebase";
import { query, collection, doc, where, getDoc, onSnapshot } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Spinner from "../components/Spinner";
import Navbar from "./Navbar";
import {
  Button,
  Tooltip,
  IconButton
} from "@material-ui/core";
import { DirectionsBoat } from "@material-ui/icons";
import { joinGameInstance } from "./JoinGameInstance";
import { GameFormatByName } from "./GameFormats"
import { Announcements } from "./Announcements"

function Dashboard() {
  const [user, loading] = useAuthState(auth);
  const [gameInstances, setGameInstances] = useState();
  const [participantsData, setParticipantsData] = useState({});
  const [playerTargetGame, setPlayerTargetGame] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGameInstances = async () => {
      try {
        const q = query(collection(db, "game_instances"), where("status", "!=", "'finished'"));
        const unsub = await onSnapshot(q, (result) => {    // onSnapshot -> updates when it changes
          setGameInstances(result.docs || []);
          for (var i = 0; i < result.docs.length; i++) {
            if (result.docs[i].data().participants.includes(user.uid)) {
              unsub();
              setPlayerTargetGame(result.docs[i].id);
            }
          }
        });
        return unsub;
      } catch (error) {
        console.error(error.message);
      }
    };

    if (loading) return;
    if (!user) {
      navigate("/");
      logout();
      return;
    }
    else {
      return fetchGameInstances();
    }
  }, [user, loading, navigate]);

  useEffect(() => {
    if (playerTargetGame) {
      navigate(`/gameroom/${playerTargetGame}`)
    }
  }, [playerTargetGame, navigate]);

  useEffect(() => {
    // load data for each participant in each game (but only once)
    if (gameInstances !== undefined) {
      const fetchParticipants = async (gameInstanceData) => {
          let participants = gameInstanceData.data().participants;
          if (participants.length > 0)
          {
            for (const item of participants) {
              if (!participantsData.hasOwnProperty(item)) {
                const player_query = query(doc(db, "users", item));
                await getDoc(player_query).then((player) => {
                  setParticipantsData((oldInfo) => {
                    let output_hash = {};
                    output_hash[item] = player.data();
                    return {...oldInfo,
                            ...output_hash};
                  });
                });
              }
            }
          }
      }

      // TODO: there should be a timeout here to keep this from running repeatedly for information that isn't that important
        for (var i = 0; i < gameInstances.length; i++) {
          fetchParticipants(gameInstances[i]);
        }
    }
  });

  if (loading || gameInstances === undefined || !user)
  {
    return (<>
      <Navbar />
      <Spinner />
    </>);
  }
  else {
    return (
      <>
      <Navbar />
      <div className="dashboard">
        {gameInstances !== undefined && gameInstances.length === 0 ? (
          <div className="placard-big">
            No games have been set up. Start one!
          </div>
        ) : (
          <div className="dashboard__available_games">
            <span className="placard"><h2>Available Games</h2></span><br />
            <table>
              <tbody>
              <tr>
                <th>Game Name</th>
                <th>Game Format</th>
                <th className="narrower_col">Host</th>
                <th className="wider_col">Participants</th>
                <th>Actions</th>
              </tr>
              {gameInstances?.map((this_game, idx) => {
                let game_format = GameFormatByName(this_game.data().game_format);
                if (game_format) {
                  let join_buttons = [];
                  const hosts = this_game.data().participants.filter(participant => this_game.data().participant_roles[participant] === 'host');

                  if (game_format.requires_host && hosts.length === 0) {
                    join_buttons.push((<Button key={ 'jb-' + idx + '-host'} onClick={ () => { joinGameInstance(this_game, user.uid, 'host') } } color="primary"> Join as Host</Button>))
                  }
                  if (game_format.team_count > 0) {
                      for (let i = 0; i < game_format.team_count; i++) {
                        join_buttons.push((<Button key={ 'jb-' + idx + '-' + i} onClick={ () => { joinGameInstance(this_game, user.uid, 'team ' + (i + 1)) } } color="primary"> Join Team {i+1} </Button>))
                      }
                  } else {
                    join_buttons.push((<Button key={ 'jb-' + idx + '-join' } onClick={ () => { joinGameInstance(this_game, user.uid, 'player') } } color="primary"> Join Game</Button>));
                  }
                  if (true) { // TODO: spectators allowed ?
                    join_buttons.push((<Button key={ 'jb-' + idx + '-watch' } onClick={ () => { joinGameInstance(this_game, user.uid, 'spectators') } } color="primary"> Spectate</Button>));
                  }

                  // TODO: this arrangement should only apply to team games
                  let player_map = {};
                  if (game_format.team_count) {
                    for (var i = 0; i < game_format.team_count; i++) {
                      player_map['team ' + (i + 1)] = [];
                    }
                  }

                  let spectator_count = 0;
                  for (let i = 0; i < this_game.data().participants.length; i++) {
                    let key = this_game.data().participants[i];
                    let role = this_game.data().participant_roles[key];
                    if (role === 'spectator') {
                      spectator_count += 1;
                    } else
                    if (role !== 'host') {
                      if (!player_map.hasOwnProperty(role))
                        player_map[role] = [];
                      player_map[role].push(key)
                    }
                  };
                  let participant_blocks = [];
                  let keys = Object.keys(player_map).sort();
                  let bg_angle = 360.0 / keys.length;
                  let fbasis = 100 / keys.length;
                  for (let i = 0; i < keys.length; i++) {
                    let group = keys[i];
                    let temp_map = player_map[group].map((val, idx) => (<div className="nickname" key={ `role-${val}-${idx}` }>{ participantsData[val]?.nickname || '(...)' }</div>));
                    if (temp_map.length === 0) {
                      temp_map = '(awaiting players)';
                    }
                    let bg_color_str = `hsl(${i * bg_angle + 25}, 60%, 60%)`;
                    participant_blocks.push(<div className="participant_block" key={ `game-participants-${idx}-${i}` } style={ { width: `${fbasis}%`, flexBasis: `${fbasis}%`, backgroundColor: bg_color_str } }>
                                              <div className="participant_group">{ group }</div>
                                              { temp_map }
                                            </div>)
                  }
// { this_game.data().participants.map((this_player) => (<span key={ 'pr-' + this_player }>{ participantsData[this_player]?.nickname || '...' } ({ this_game.data().participant_roles[this_player] })<br /></span>)) }
                  return (
                    <tr key={ "game-" + idx }>
                      <td>
                        <span><b>{ this_game.data().name }</b></span>
                      </td>
                      <td>
                        <span><b><Tooltip title={ game_format.description }><IconButton><DirectionsBoat />&nbsp; { this_game.data().game_format }</IconButton></Tooltip></b></span>
                      </td>
                      <td className="narrower_col">
                        { hosts.length > 0 ? (participantsData[hosts]?.nickname || '...') : 'no host yet' }
                      </td>
                      <td className="wider_col">
                        <div className="participant_cell">
                          { participant_blocks }
                        </div>
                        <div className="spectator_count">{ (spectator_count > 0 ) ? `+ ${spectator_count} spectators` : '' }</div>
                      </td>
                      <td>
                      { join_buttons }
                      </td>
                    </tr>
                  )
                } else {
                  return null;
                }
              })}
              </tbody>
            </table>
          </div>
        )}
        <br />
        <div className="info_wrapper">
          <Announcements />
          <div className="activity">
            <span>Activity Feed</span><br />
            <p><b>So-and-So</b> beat <b>Whozie</b>, 9-5</p>
            <p><b>Saul Goodman</b> earned the <b>25 Games Played</b> merit badge</p>
            <p>(and that sort of thing...)</p>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default Dashboard;
