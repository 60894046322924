import React, { useEffect, useState } from "react";
import './Timer.css';

import { PlayArrow, Pause, Stop } from "@material-ui/icons";
import { Button } from "@material-ui/core";

import { getDatabase, set, ref, onValue, serverTimestamp, get } from "firebase/database"; // realtime

const TIMES_UP = '000.00';
var timer_interval = null;

// TODO: this is defined also in the game MerseysideHarsh
const padded = (text, size) => {
    var s = "000000" + text;
    return s.substr(s.length-size);
}

const Timer = ({ gameId, isHost }) => {
  const [currentTimerData, setCurrentTimerData] = useState(TIMES_UP);
  const [timerStatus, setTimerStatus] = useState('none');

  const set_timer = async (gameId, mseconds, grace_period) => {
    const db = getDatabase();
    const timer_ref = ref(db, `timers/${gameId}`);

    let timer_data;

    timer_data = {
      mseconds: mseconds,
      grace_period: grace_period,
      start_at: serverTimestamp(),
      status: 'set'
    };
    console.log('initial set', timer_data);
    set(timer_ref, timer_data);
  };

  const pause_timer = async (gameId) => {
    const db = getDatabase();
    const timer_ref = ref(db, `timers/${gameId}`);

    await get(timer_ref).then((old_data) => {

      let mseconds = old_data.val().mseconds - (Date.now() - old_data.val().start_at); // skipping offset...?

      // set seconds to remaining time
      let timer_data = {
        ...old_data.val(),
        mseconds: mseconds,
        status: 'paused'
      };
      console.log(old_data.val(), timer_data);

      set(timer_ref, timer_data);
    });
  };

  const start_timer = async (gameId) => {
    const db = getDatabase();
    const timer_ref = ref(db, `timers/${gameId}`);

    await get(timer_ref).then((old_data) => {

      // set seconds to remaining time
      let timer_data = {
        mseconds: old_data.val().mseconds,
        start_at: serverTimestamp(),
        status: 'running'
      };

      set(timer_ref, timer_data);
    });
  };

  const stop_timer = async (gameId) => {
    const db = getDatabase();
    const timer_ref = ref(db, `timers/${gameId}`);

    let timer_data = {
      mseconds: 0,
      start_at: serverTimestamp(),
      status: 'stopped'
    };
    set(timer_ref, timer_data);
  };

  const fetchTimer = async () => {
    const db = getDatabase();
    var estimatedServerTimeMs;

    const countdown = (mseconds, start_at, offset) => {
      let timeLeft = 0;
      if (start_at) {
        timeLeft = mseconds - (Date.now() - start_at - offset);
      } else {
        timeLeft = mseconds;
      }

      if (timeLeft <= 0) {
        clearInterval(timer_interval);
        timer_interval = null;
        setCurrentTimerData(TIMES_UP);
        setTimerStatus('stopped');
      }
      else {
        let time_left = padded((timeLeft/1000.0).toFixed(2), 6);
        setCurrentTimerData(time_left);
      }
    }

    const timer = ref(db, `timers/${gameId}`);
    const unsub = onValue(timer, (result) => {

      const rdata = result.val();
      if (rdata) {

        const mseconds = result.val().mseconds;
        const start_at = result.val().start_at;
        const sitch = result.val().status;
        setTimerStatus(sitch);

        if (sitch === 'stopped') {
          countdown(0);
          clearInterval(timer_interval);
          timer_interval = null;
        }
        else if (timer_interval !== null && sitch === 'paused'){
          clearInterval(timer_interval);
          timer_interval = null;
        } else if (timer_interval === null && mseconds && mseconds > 0) {
          // console.log('adding timer');
          const offsetRef = ref(db, ".info/serverTimeOffset");
          onValue(offsetRef, (snap) => {
            estimatedServerTimeMs = snap.val();
          });

          if (sitch === 'running') {
            timer_interval = setInterval(() => countdown(mseconds, start_at, estimatedServerTimeMs), 97); // 97msec, so the ones place also changes
          } else if (sitch === 'set') {
            countdown(mseconds);
          }
        }
      }
    });
    return unsub;
  };

  useEffect(() => {
    return fetchTimer();
  } );

  let buttons = [];
  if (isHost) {
    buttons = (<div className="timer-buttons">
                <Button className="time-rare" disabled={ timerStatus==='running' } onClick={ () => set_timer(gameId, 300000, 2) }>5 min</Button>
                <Button className="time-rare" disabled={ timerStatus==='running' } onClick={ () => set_timer(gameId, 120000, 2) }>2 min</Button>
                <Button className="time-rare" disabled={ timerStatus==='running' } onClick={ () => set_timer(gameId, 60000, 2) }>1 min</Button>
                <Button className="time" disabled={ timerStatus==='running' } onClick={ () => set_timer(gameId, 30000, 2) }>30 sec</Button>
                <Button className="time" disabled={ timerStatus==='running' } onClick={ () => set_timer(gameId, 20000, 2) }>20 sec</Button>
                <Button className="time" disabled={ timerStatus==='running' } onClick={ () => set_timer(gameId, 5000, 1) }>5 sec</Button>
                <Button className="start" disabled={ timerStatus==='running' || timerStatus==='none' || currentTimerData === TIMES_UP } onClick={ () => start_timer(gameId) }><PlayArrow /></Button>
                <Button className="pause" disabled={ timerStatus==='paused' || timerStatus==='stopped' || timerStatus==='set' || timerStatus==='none' || currentTimerData === TIMES_UP } onClick={ () => pause_timer(gameId) }><Pause /></Button>
                <Button className="stop" disabled={ timerStatus==='stopped' || timerStatus==='set' || timerStatus==='none' } onClick={ () => stop_timer(gameId) }><Stop /></Button>
              </div>);
  }

  // start shifting the color when there is only 10 seconds left
  let color_shift = (currentTimerData + 0) / 10;
  if ((color_shift) > 1) {
    color_shift = 1;
  }
  let color_angle = 120 * color_shift;

  return (<div className="led-timer" id="timer">
            { buttons }
            <div className="timer-counter" style={ { color: `hsl(${color_angle}, 100%, 50%)` } }>
              { currentTimerData }s
            </div>
          </div>);
}

export default Timer;
