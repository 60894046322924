import './JitsiVideoWrapper.css';
import React, { useEffect, useState } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import Spinner from "../components/Spinner";
import { auth } from "../firebase";
import GameRoom from "./GameRoom"
import { CircularProgress } from "@material-ui/core";

const DEBUG_MODE = true;
// DEACTIVATED MODE just shuts off video altogether
const DEACTIVATED_MODE = false;

// Must use "beta" in local dev without HTTPS (http://)
const USE_BETA_JITSI_SERVER = !!window.location.href.match(/http:\/\//);
// console.log('running at ', window.location.href, 'beta server is active?:', USE_BETA_JITSI_SERVER);

const debug_message = (...args) => {
  if (DEBUG_MODE) {
    for (var i = 0; i < args.length; i++) {
      console.log('DEBUG', args);
    }
  }
}

function clone(hash) {
  var newObj = {};
  if (hash !== undefined) {
    Object.keys(hash).forEach(function(key) {
      newObj[ key ] = hash[ key ];
    });
  }

  return newObj;
}

const JitsiVideoWrapper = ({ conferenceRoomName, gameId }) => {
  const [user, loading] = useAuthState(auth);
  const JitsiMeetJS = window.JitsiMeetJS;

  var options; // TODO: figure out how to make this dynamic for push (though then local will not talk to remote)
  if (USE_BETA_JITSI_SERVER) {
    const serverURL = 'beta.meet.jit.si';
    options = {
      hosts: {
        domain: serverURL,
        muc: `conference.${serverURL}`,
      },
      serviceUrl: `https://${serverURL}/http-bind`,
      clientNode: `https://${serverURL}`
    };
  } else {
    const serverURL = 'meet.jit.si';
    options = {
      hosts: {
        domain: serverURL,
        focus: `focus.${serverURL}`,
        muc: `conference.${serverURL}`,
      },
      bosh: `//${serverURL}/http-bind?conference?room=${conferenceRoomName}`,
      clientNode: `http://jitsi.org/jitsimeet`
    };
  }
  options = {
    ...options,
    disableSimulcast: false,
    constraints: {
        video: {
            height: {
                ideal: 720,
                max: 720,
                min: 180
            },
            width: {
                ideal: 1280,
                max: 1280,
                min: 320
            }
        }
    },
    enableInsecureRoomNameWarning: true,
    externalConnectUrl: '//meet.jit.si/http-pre-bind',
/* analytics: {
            amplitudeAPPKey: "fafdba4c3b47fe5f151060ca37f02d2f",
        rtcstatsEnabled: true ,
    rtcstatsUseLegacy: false ,
    rtcstatsEndpoint: "wss://rtcstats-server.jitsi.net/",
    rtcstatsPollInterval: 10000,
                    whiteListedEvents: [ 'conference.joined', 'page.reload.scheduled', 'rejoined', 'transport.stats', 'rtcstats.trace.onclose', 'audio.play.error', 'audio.play.success', 'reaction.button.clicked', 'reaction.settings.sounds.disabled', 'poll.create', 'poll.vote.checked', 'poll.vote.sent', 'poll.vote.skipped', 'poll.vote.detailsViewed', 'poll.vote.changed', 'poll.option.added', 'poll.option.moved', 'poll.option.removed', 'breakout.rooms.create.button.clicked', 'breakout.rooms.close.button.clicked', 'breakout.rooms.remove.button.clicked', 'breakout.rooms.auto.assign.button.clicked', 'breakout.rooms.join.button.clicked', 'breakout.rooms.send.participant.to.room.button.clicked' ],
    }, */
    enableP2P: true, // flag to control P2P connections
    // New P2P options
    p2p: {
        enabled: true,
        enableUnifiedOnChrome: true,
        preferredCodec: 'VP9',
        disableH264: true,
        useStunTurn: true // use XEP-0215 to fetch STUN and TURN servers for the P2P connection
    },
    useStunTurn: true, // use XEP-0215 to fetch TURN servers for the JVB connection
    useTurnUdp: false,
    // bosh: '//meet.jit.si/http-bind', // FIXME: use xep-0156 for that
    websocket: 'wss://meet.jit.si/xmpp-websocket', // FIXME: use xep-0156 for that
    websocketKeepAliveUrl: 'https://meet.jit.si/_unlock',


    // clientNode: 'http://jitsi.org/jitsimeet', // The name of client node advertised in XEP-0115 'c' stanza
    //deprecated desktop sharing settings, included only because older version of jitsi-meet require them
    desktopSharing: 'ext', // Desktop sharing method. Can be set to 'ext', 'webrtc' or false to disable.
    chromeExtensionId: 'kglhbbefdnlheedjiejgomgmfplipfeb', // Id of desktop streamer Chrome extension
    desktopSharingSources: ['screen', 'window'],
    // googleApiApplicationClientID: "39065779381-bbhnkrgibtf4p0j9ne5vsq7bm49t1tlf.apps.googleusercontent.com",
    // microsoftApiApplicationClientID: "00000000-0000-0000-0000-000040240063",
    enableCalendarIntegration: true,
    //new desktop sharing settings
    desktopSharingChromeExtId: 'kglhbbefdnlheedjiejgomgmfplipfeb', // Id of desktop streamer Chrome extension
    desktopSharingChromeSources: ['screen', 'window', 'tab'],
    enableLipSync: false,
    enableSaveLogs: false,
    disableRtx: false, // Enables RTX everywhere
    channelLastN: 25, // The default value of the channel attribute last-n.
    videoQuality: {
        preferredCodec: 'VP9',
    maxBitratesVideo: {
        VP8: {
            low: 200000,
            standard: 500000,
            high: 1500000
        },
        VP9: {
            low: 100000,
            standard: 300000,
            high: 1200000
        }
    },
            },
    useNewBandwidthAllocationStrategy: true,
    startBitrate: "800",
    disableAudioLevels: false,
    stereo: false,
    forceJVB121Ratio:  -1,
    enableTalkWhileMuted: true,
    mouseMoveCallbackInterval: 1000,
    enableNoAudioDetection: true,
    enableNoisyMicDetection: true,

    enableClosePage: true,


    disableLocalVideoFlip: false,

/* hiddenDomain: 'recorder.meet.jit.si',
    dropbox: {
        appKey: '3v5iyto7n7az02w'
    },


    transcribingEnabled: false,
    liveStreamingEnabled: true,
    fileRecordingsEnabled: true,
    fileRecordingsServiceEnabled: false,
    fileRecordingsServiceSharingEnabled: false,
    requireDisplayName: false,
    enableWelcomePage: true,
    isBrand: false,
// To enable sending statistics to callstats.io you should provide Applicaiton ID and Secret.
    callStatsID: "574005334",//Application ID for callstats.io API
    callStatsSecret: "sBRvEnCkjJMnkhNy2ufsEaUt1MdMPxR2WQqfO+jB6Lk=",//Secret for callstats.io API
    callStatsApplicationLogsDisabled: true,
    callStatsCustomScriptUrl: "https://api.callstats.io/static/callstats-ws.min.js",
    dialInNumbersUrl: 'https://api.jitsi.net/phoneNumberList',
    dialInConfCodeUrl:  'https://api.jitsi.net/conferenceMapper',

    dialOutCodesUrl:  'https://api.jitsi.net/countrycodes',
    dialOutAuthUrl: 'https://api.jitsi.net/authorizephone',
    peopleSearchUrl: 'https://api.jitsi.net/directorySearch',
    inviteServiceUrl: 'https://api.jitsi.net/conferenceInvite',
    inviteServiceCallFlowsUrl: 'https://api.jitsi.net/conferenceinvitecallflows',
    peopleSearchQueryTypes: ['user','conferenceRooms'],
    startAudioMuted: 25,
    startVideoMuted: 25,
    enableUserRolesBasedOnToken: false,
    enableLayerSuspension: true,
        enableUnifiedOnChrome: true,
    enableForcedReload: true,
feedbackPercentage: 0,
    deploymentUrls: {
        userDocumentationURL: "https://jitsi.github.io/handbook/help",
    },
    chromeExtensionBanner: {
        url: "https://chrome.google.com/webstore/detail/jitsi-meetings/kglhbbefdnlheedjiejgomgmfplipfeb",
        chromeExtensionsInfo: [{"id": "kglhbbefdnlheedjiejgomgmfplipfeb", "path": "jitsi-logo-48x48.png"}]
    },
    prejoinPageEnabled: true,
    moderatedRoomServiceUrl: 'https://moderated.jitsi.net',
    disableSpeakerStatsSearch: true,
    enableInsecureRoomNameWarning: true,
    screenshotCapture:{
        enabled: false ,
        mode: 'recording'
    },
    toolbarConfig: {
        timeout: 4000,
        initialTimeout: 20000
    },
    hepopAnalyticsUrl: "",
    hepopAnalyticsEvent: {
        product: "lib-jitsi-meet",
        subproduct: "meet-jit-si",
        name: "jitsi.page.load.failed",
        action: "page.load.failed",
        actionSubject: "page.load",
        type: "page.load.failed",
        source: "page.load",
        attributes: {
            type: "operational",
            source: 'page.load'
        },
        server: "meet.jit.si"
    }, */
    deploymentInfo: {
        environment: 'meet-jit-si',
        envType: 'prod',
        releaseNumber: '2572',
        shard: 'meet-jit-si-us-east-1b-s7',
        region: 'us-east-1',
        userRegion: 'us-east-1',
        crossRegion: 0
    },
    e2eping: {
        pingInterval: 1
    },
    abTesting: {
    },
    testing: {
        enableThumbnailReordering: true,
            callStatsThreshold: 1, // % of users that will have callStats enabled.
                    capScreenshareBitrate: 1,
    }
  }

  const confOptions = {
    /* I'm really not sure if this is doing anything */
    'lastN': 20, // Number of videos requested from the bridge.
    // 'selectedEndpoints': ['A', 'B', 'C'], // The endpoints ids of the participants that are prioritized first.
    // 'onStageEndpoints': ['A'], // The endpoint ids of the participants that are prioritized up to a higher resolution.
    'defaultConstraints': { 'maxHeight': 180 }, // Default resolution requested for all endpoints.
    'constraints': { // Endpoint specific resolution.
        video: { aspectRatio: 16 / 9, width: { ideal: 720, max: 720, min: 240 } }
    },
    startVideoMuted: true,
    deploymentInfo: {},
    addFeature: { feature: 'virtual-background' } // I don't think this is working
  };

  const [messages, setMessages] = useState([]);
  const [getRoom, setRoom] = useState();
  const [connection, setConnection] = useState();
  const [jitsiInitialized, setJitsiInitialized] = useState(false);

  const receiveMessage = (message) => {
    setMessages((oldMsgs) => { return oldMsgs.concat(message) });
  }

  const onConnectionSuccess = () => {
      debug_message('connection successful');

      let room = connection.initJitsiConference(conferenceRoomName, confOptions)
      room.setReceiverConstraints(confOptions); // no idea if this is doing anything
      room.setDisplayName(user.uid);
      room.join();

      // chat is handled here...
      room.on(JitsiMeetJS.events.conference.MESSAGE_RECEIVED, (sender, content) => { receiveMessage(content) })
      debug_message('room joined', room);

      setJitsiInitialized(true);
      setRoom(room);
  }

  const onConnectionFailed = () => {
    debug_message('Connection Failed!', user.uid);
  }

  const disconnect = () => {
    debug_message('disconnected and removing listeners');
    connection.removeEventListener(
        JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
        onConnectionSuccess);
    connection.removeEventListener(
        JitsiMeetJS.events.connection.CONNECTION_FAILED,
        onConnectionFailed);
    connection.removeEventListener(
        JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
        disconnect);
  }

  useEffect(() => {
    const jitsiStartup = () => {
      if (!jitsiInitialized) {
        debug_message('initializing Jitsi');

        JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.INFO);
        const initOptions = {
            disableAudioLevels: true,
            enableAnalyticsLogging: false,

        };

        JitsiMeetJS.init(initOptions);
        setConnection(new JitsiMeetJS.JitsiConnection(null, null, options));
      }
    }

    const jitsiShutdown = () => {
      if (!DEACTIVATED_MODE) {
        debug_message('jitsi shutdown', getRoom);

        try {
          getRoom && getRoom.leave();
          connection.disconnect();
        } catch (err) {
          console.log(err);
        }
      }
    }

    if (loading || !user) {
      return (<Spinner />);
    }
    if (!DEACTIVATED_MODE && user) {
       debug_message('init startup');
       jitsiStartup();
       return jitsiShutdown;
    }
  }, [loading, user]);

  useEffect(() => {
    if (connection) {
      connection.addEventListener(
          JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
          onConnectionSuccess);
      connection.addEventListener(
          JitsiMeetJS.events.connection.CONNECTION_FAILED,
          onConnectionFailed);
      connection.addEventListener(
          JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
          disconnect);

    /*  JitsiMeetJS.mediaDevices.addEventListener(
          JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED,
          onDeviceListChanged); */

      debug_message('initializing connection');
      connection.connect();
    }
  }, [connection]);

  let chat = { sendMessage: (text) => getRoom.sendTextMessage(text),
               messages: messages }

  return (<div className="jitsi-video-wrapper">
              <GameRoom gameId={ gameId } jitsiRoom={ getRoom } chat={ chat } />
          </div>);
};

const VideoSlot = ({ participantId, trackControls, jitsiRoom }) => {
  const JitsiMeetJS = window.JitsiMeetJS;

  const [user] = useAuthState(auth);
  const [video_ref, setVideoRef] = useState(React.createRef());
  const [audio_ref, setAudioRef] = useState(React.createRef());
  const [viewerUpdate, setViewerUpdate] = useState(0);

  const [associatedTracks, setAssociatedTracks] = useState();

  const [vidMuted, setVidMuted] = useState(true);
  const [audMuted, setAudMuted] = useState(true);

  let is_local_user = (participantId === user.uid);

  const getParticipantUid = (participant) => {
    debug_message('get participant UID for', participant);

    let participants = jitsiRoom.getParticipants();
    let remote_uid = null;

    debug_message('participants are', participants);
    for (let i = participants.length-1; i >= 0; i--) {
      if (participants[i]._id === participant)
      {
        remote_uid = participants[i]._displayName;
        break;
      }
    }
    return remote_uid;
  }

  const setRemoteMuting = (track) => {
    const participant = track.getParticipantId();
    let remote_uid = getParticipantUid(participant);

    if (participantId === remote_uid) {
      if (track.getType() === 'video') {
        setVidMuted(track.isMuted()); // or offline?
      } else {
        setAudMuted(track.isMuted()); // or offline?
      }
    }
  }

  const closeTrack = (track) => {
    var remote_uid = getParticipantUid(track.getParticipantId());
    debug_message('setting remote muting', remote_uid, track, true);
    setRemoteMuting(track);
  }

  useEffect(() => {
    if (jitsiRoom) {
      debug_message('room is', jitsiRoom)
    }

    /* const onUserLeft = (p_id) => {
      debug_message('a user left', p_id);
      if (p_id) {
        let remote_uid = getParticipantUid(p_id);

        if (associatedTracks && remote_uid === participantId) {
          for (var i = 0; i < associatedTracks.length; i++) {
            associatedTracks[i].mute();
            associatedTracks[i].detach();
          }
        }
      }
    } */

    const onRemoteTrack = async (track) => {
        debug_message('remote track received', track);
        if (!track || track.isLocal()) { // this really should never happen...
            return;
        }
        const participant = track.getParticipantId();
        let remote_uid = getParticipantUid(participant);

        if (!remote_uid) {
          return;
        }

        if (remote_uid === participantId) {
          if (track.getType() === 'video') {
            if (!video_ref)
            {
              setVideoRef(React.createRef());
            }

            debug_message('attaching remote video', track, video_ref.current);
            track.attach(video_ref.current);
            setVidMuted(track.isMuted());
          } else {
            if (!audio_ref)
            {
              setAudioRef(React.createRef());
            }

            debug_message('attaching remote audio', track, audio_ref.current);
            track.attach(audio_ref.current);
            setAudMuted(track.isMuted());
          }
          if (associatedTracks) {
            setAssociatedTracks((oldTracks) => {
              const newTracks = [];
              for (var i = 0; i < oldTracks.length; i++) {
                newTracks.push(oldTracks[i]);
              }
              if (newTracks.indexOf(track) === -1) {
                  newTracks.push(track);
              }
              return newTracks;
            });
          }


          track.addEventListener(
              JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
              audioLevel => debug_message(`Audio Level remote: ${audioLevel}`));
          track.addEventListener(
              JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,
              (track) => {
                debug_message('remote track muted')
                setRemoteMuting(track);

              });
          track.addEventListener(JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED,
              deviceId =>
                  debug_message(
                      `track audio output device was changed to ${deviceId}`));
        }
    }

    const onLocalTracksFn = (tracks) => {
        debug_message('local track received', tracks.length, tracks);
        setAssociatedTracks(tracks);

        for (let i = 0; i < tracks.length; i++) {
          debug_message('setting', tracks[i]);
            tracks[i].addEventListener(
                JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
                audioLevel => debug_message(`Audio Level local: ${audioLevel}`));
            tracks[i].addEventListener(
                JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,
                () => debug_message('local track muted'));
            tracks[i].addEventListener(
                JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
                () => debug_message('local track stopped'));
            tracks[i].addEventListener(
                JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED,
                deviceId => debug_message(`track audio output device was changed to ${deviceId}`));

            tracks[i]._userId = user.uid;
            tracks[i].unmute();
            jitsiRoom.addTrack(tracks[i]);

            if (tracks[i].getType() === 'video') {
              debug_message('attaching video', tracks[i], video_ref.current);
              tracks[i].attach(video_ref.current);
              setVidMuted(tracks[i].isMuted());
            } else {
              debug_message('attaching audio', tracks[i], audio_ref.current);
              tracks[i].attach(audio_ref.current);
              setAudMuted(tracks[i].isMuted());
            }
        }
    }

    const onConferenceJoined = () => {
        debug_message('conference joined', jitsiRoom);

        JitsiMeetJS.createLocalTracks({ devices: [ 'audio', 'video' ],
      facingMode: 'user',
    fireSlowPromiseEvent: true })
            .then(onLocalTracksFn)
            .catch(error => {
                throw error;
            });
    }

    if (jitsiRoom) {
      if (is_local_user) {
        debug_message('local user check?', user.uid, is_local_user);
        debug_message('adding local user handlers');
        jitsiRoom.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, onConferenceJoined);
      }
      else {
        debug_message('adding remote user handlers');
        jitsiRoom.on(JitsiMeetJS.events.conference.TRACK_ADDED, onRemoteTrack);
        //    jitsiRoom.on(JitsiMeetJS.events.conference.TRACK_REMOVED, (track) => closeTrack(track) ); // i.e. a remote user shut off video, muted, whatever.
      }

      // AFAICT these don't matter
      jitsiRoom.on(JitsiMeetJS.events.conference.USER_JOINED, id => { // note that this is also executed locally for all the users already present, when "YOU" join
          // remoteTracks[id] = [];
      });
      // jitsiRoom.on(JitsiMeetJS.events.conference.USER_LEFT, onUserLeft);
      /* jitsiRoom.on(JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED, track =>
        { debug_message(`set ${track.getType()} to ${track.isMuted()}`); });

      jitsiRoom.on(
          JitsiMeetJS.events.conference.DISPLAY_NAME_CHANGED,
          (userID, displayName) => debug_message(`${userID} - ${displayName}`));
      jitsiRoom.on(
          JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED,
          (userID, audioLevel) => debug_message(`${userID} - ${audioLevel}`)); */
    }

  }, [jitsiRoom, viewerUpdate]);

  const mute_toggle = (track) => {
    let state = track.isMuted();
    if (track.getType() === 'video') {
      setVidMuted(!state);
    } else {
      setAudMuted(!state);
    }
    state ? track.unmute() : track.mute();
  };

  let aud_track, vid_track;
  if (associatedTracks) {
    for (var i = 0; i < associatedTracks.length; i++) {
      if (associatedTracks[i].getType() === 'video') {
        vid_track = associatedTracks[i];
      } else {
        aud_track = associatedTracks[i];
      }
    }
  }

  useEffect(() => {
    const detachTracks = () => {
      debug_message('detaching tracks', associatedTracks);

      if (associatedTracks) {
        for (var i = 0; i < associatedTracks.length; i++) {
          console.log(associatedTracks[i]);
          if (associatedTracks[i].isLocal()) {
            associatedTracks[i].mute();
          }
          associatedTracks[i].detach();
          if (associatedTracks[i].isLocal()) {
            associatedTracks[i].dispose();
          }
        }
      }
    }

    return () => {
        if (is_local_user) {
          detachTracks();
        }
      }
  }, [associatedTracks, viewerUpdate]);

  const reset = () => {
    setViewerUpdate(viewerUpdate + 1);
  }

// <div className="video-waiter-spin" style={ { display: vid_track ? 'none' : 'flex' }}><CircularProgress /></div>
  return (<div>
            <div className="video-stream-wrapper" id={ 'stream-player-' + participantId }>
              { vidMuted ?
                <img alt="video offline" src="/video_offline.png" className="screenglitch" /> : ''}
              <video ref={ video_ref } key={`vid-${participantId}`} autoPlay={ true } className='video-stream' style={ { display: vidMuted ? 'none' : 'flex' } } />
              { audMuted ?
                <img alt="muted mic" src="/muted_mic.png" className="muted-mic" /> :
                <img alt="unmuted mic" src="/unmuted_mic.png" className="unmuted-mic" /> }
              <audio ref={ audio_ref } muted={ is_local_user } key={`aud-${participantId}`} autoPlay={ true } className='audioStream' />
            </div>
            { is_local_user ?
              (<div className="track-controls">
                { vid_track ? (<button className="vid" onClick={ () => mute_toggle(vid_track) }>{ vidMuted ? 'start camera' : 'stop camera' }</button>) : '' }
                { aud_track ? (<button className="mic" onClick={ () => mute_toggle(aud_track) }>{ audMuted ? 'unmute mic' : 'mute mic' }</button>) : '' }
               </div>) : null }
          </div>);
}
// (<button className="vid" onClick={ reset }>RESET</button>) }

export { JitsiVideoWrapper, VideoSlot, USE_BETA_JITSI_SERVER };
