import React, { useEffect, useState } from "react";
import "./Announcements.css";
import { db } from "../firebase";
import { query, collection, getDocs } from "firebase/firestore";
import ReactMarkdown from 'react-markdown';

const Announcement = ({ announcement }) => {
  let date;

  if (announcement?.date) {
    date = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(announcement.date.seconds * 1000);
  };

  if (announcement?.name && announcement?.content) {
    return (
      <div className="announcement">
        <div className="announcement__header">
          <div className="announcement__name">{announcement.name}</div>
          <div className="announcement__date">{ date }</div>
        </div>
        <div className="announcement__content"><ReactMarkdown>{ announcement.content }</ReactMarkdown></div>
      </div>
    ); }
  else {
    return null;
  }
}

const Announcements = () => {
  const [announcements, setAnnouncements] = useState([]);

  const fetchAnnouncements = async () => {
    try {
      const anno_q = query(collection(db, "announcements"));
      let annos = await getDocs(anno_q);
      setAnnouncements(annos.docs);
    } catch(err) {
      alert('Cannot load announcements: ' + err.message);
    }
  };

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  return(<div className="announcements">
           <span>Announcements</span>
           <br />
           { (announcements || []).map((item) => { return (<Announcement key={ item.id } announcement={ item.data() } />) }) }
        </div>);
}

export { Announcement, Announcements };
