import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { VideoSlot } from "../JitsiVideoWrapper"
import './AllFormats.css';
import Timer from '../Timer';

import { updateDoc } from "firebase/firestore";

// TODO: temporary
// import { MerseysideHarshDummyQuiz } from '../../testing/MerseysideHarshDummyQuiz'

import { auth } from "../../firebase";

const Spectators = ({ gameInstanceData, participantsData, selection, setTrackRefs, trackControls }) => {
  const [user] = useAuthState(auth);

  let slots = [];
  for (var i = 0; i < selection.length; i++)
  {
    let spectator = selection[i];
    slots.push(<div key={ 'spectator-' + i }>
                <div className="spectator-slot" id={ "spectator-" + spectator }>
                  <div className='spectator-placement' id={ 'stream-player-wrapper-' + spectator }>
                    <VideoSlot id={ spectator } setTrackRefs={ setTrackRefs } trackControls={ trackControls }></VideoSlot>
                  </div>
                  <div className="spectator_label">{ spectator === user.uid ? "(you) " : ''}{ participantsData[spectator]?.nickname }</div>
                </div>
            </div>)
  }

  return (
    <div id="spectators">
      <div className="spectator_header">SPECTATORS</div>
      <div className="spectator_column">
        { slots }
      </div>
    </div>
  )
}

const MerseysideHarshScoreBoard = ({ gameInstanceData, player, children }) => {
  return (<div className='scoreboard' id={ 'scoreboard-player-' + player }>
  {children}
  </div>)
};

/*
const Controls = () => {
  return (<div className='controls'>

          </div>)
} */

const increment_score = (count, gameInstanceData, player, teamId, mates) => {
    try {
      let gdata = gameInstanceData.data();

      let teamScore = 0;
      for (var i = 0; i < mates.length; i++)
      {
          if (mates[i] !== player) {
            teamScore += (gdata.player_scores[mates[i]] || { 'total': 0})['total'];
          }
      }

      let tempCurTeamScore = gdata['team_scores'] || [0,0,0];

      let tempCurPlayerScore = gdata['player_scores'] || {};
      if (!tempCurPlayerScore[player]) {
        tempCurPlayerScore[player] = { 1: 0, 2: 0, total: 0 };
      }

      // inc. 2 pointer count
      tempCurPlayerScore[player][Math.abs(count)] += (count > 0 ? 1 : -1);
      if (tempCurPlayerScore[player][Math.abs(count)] < 0)
      {
        tempCurPlayerScore[player][Math.abs(count)] = 0;
      }

      tempCurPlayerScore[player]['total'] = (tempCurPlayerScore[player][1] + 2 * tempCurPlayerScore[player][2]);
      tempCurTeamScore[teamId] = teamScore + tempCurPlayerScore[player]['total'];

      // RESET
      // tempCurPlayerScore[player] = { 1: 0, 2: 0, total: 0 };
      // tempCurTeamScore[teamId] = 0;

      let new_data = {
        team_scores: tempCurTeamScore,
        player_scores: tempCurPlayerScore
      }
      updateDoc(gameInstanceData.ref, new_data);
    } catch (e) {
      alert(e);
    }
}

// TODO: these buttons shouldn't be here. Just a +2, a +1 for each other player, and a "WRONG" button; then flip, with +1 for all opponents.
// Game position should be in a stack that can be undone (and perhaps even re-done, until changed)
const ScoreControls = ({ game, participantsData, player, teamId, mates, chat }) => {
  let nick = participantsData[player]?.nickname;
  return (<div className="score-buttons">
            <div className="good-score">
              <button onClick={ () => {
                                        increment_score(2, game, player, teamId, mates)
                                        chat.sendMessage("RefBot||||" + nick + " was awarded 2 points");
                                      }}>+2</button>
              <button onClick={ () => {
                                        increment_score(1, game, player, teamId, mates)
                                        chat.sendMessage("RefBot||||" + nick + " was awarded 1 point");
                                      }}>+1</button>
            </div>
            <div className="bad-score">
              <button disabled={ player_scores(game, player)[teamId]['total'] <= 0 } onClick={ () => {
                   increment_score(-1, game, player, teamId, mates)
                   chat.sendMessage("RefBot||||Score correction; 1 point removed from " + nick) }}>-1</button>
              <button disabled={ player_scores(game, player)[teamId][2] <= 0 } onClick={ () => {
                increment_score(-2, game, player, teamId, mates)
                chat.sendMessage("RefBot||||Score correction; 2 points removed from " + nick) }}>-2</button>
            </div>
          </div>)
}

const MerseysideHarshContestants = ({ gameInstanceData, participantsData, selection, teamId, host, setTrackRefs, trackControls, chat }) => {
  let podiums = [];
  const [user] = useAuthState(auth);

  for (var i = 0; i < selection.length; i++)
  {
    let player = selection[i];
    let pscores = player_scores(gameInstanceData, player)
    podiums.push(<div key={ 'contestant-' + i } className="merseyside-podium" id={ "contestant-" + player }>
                    <div className='merseyside-team-placement' id={ 'stream-player-wrapper-' + player }>
                      <VideoSlot id={ player } setTrackRefs={ setTrackRefs } trackControls={ trackControls }></VideoSlot>
                    </div>
                    <MerseysideHarshScoreBoard gameInstanceData={ gameInstanceData } player={ player }>
                      <div className="placard">
                        { participantsData[player]?.nickname }{ player === user.uid ? ' (you)' : '' } <b>&nbsp;{ pscores.total }&nbsp;</b> ({ pscores[2] }|{ pscores[1] })
                      </div>
                    </MerseysideHarshScoreBoard>
                    { host === user.uid ? (<ScoreControls game={ gameInstanceData } participantsData={ participantsData } player={ player } teamId={ teamId } mates={ selection } chat={ chat }/>) : ''}
                  </div>)
  }

  return (
    <div className="merseyside-team" id={ 'team-' + teamId }>
      <div className='placard team-header'>{ teamId === 1 ? 'HOME' : 'AWAY' }</div>
      <div className="merseyside-flexwrap">
        { podiums.length > 0 ? podiums : (<div className="placard placard-narrow">No players have joined this team yet</div>) }
      </div>
    </div>
  );
};

const HostControls = ({ gameInstanceData, player }) => {
  // <div className="placard">Host Control Panel</div>
  return (<div className="merseyside-host-control-panel">
          </div>);
}

const padded = (text, size) => {
    var s = "000000" + text;
    return s.substr(s.length-size);
}

const team_scores = (gameInstanceData) => {
  return (gameInstanceData.data()['team_scores'] || [0,0,0]);
}

const player_scores = (gameInstanceData, player) => {
  if (gameInstanceData.data().hasOwnProperty('player_scores') && gameInstanceData.data().player_scores.hasOwnProperty(player))
    return gameInstanceData.data().player_scores[player];
  return { 1: 0, 2: 0, total: 0 };
}

const TeamScoreboards = ({ gameInstanceData }) => {
  return (<div className='central-info'>
            <div className="team-scoreboard">
            <div className="placard">SCORES</div>
            <div className="scoreboard-flex">
              <div className="led-scoreboard" id="team-1-score">{ padded(team_scores(gameInstanceData)[1], 3) }</div>
              <div className="led-scoreboard" id="team-2-score">{ padded(team_scores(gameInstanceData)[2], 3) }</div>
            </div>
          </div>
        </div>);
}

const Chatbox = ({ participantsData, chat }) => {
  const [user] = useAuthState(auth);
  const [chatText, setChatText] = useState("");

  const sendChat = () => {
    chat.sendMessage(participantsData[user.uid]?.nickname + "||||" + chatText);
    setChatText("");
  }

  const chat_bottom_ref = React.createRef();
  const submit_button_ref = React.createRef();

  useEffect(() => {
    chat_bottom_ref.current.scrollIntoView({ behavior: 'smooth' });
  }, [chat_bottom_ref, chat.messages]);

  const handleKeys = (e) => {
    if (e.which === 13 && !e.shiftKey) {
        e.preventDefault();

        sendChat();
    }
  };

  return (<div className="chatbox-wrapper">
            <div className="chatbox-messages">
              { chat.messages.map((msg, idx) => {
                 let parts = msg.split("||||", 2);
                 let speaker = parts[0];
                 let message = parts[1];
                 return (<p key={ `msg-${idx}` } className="chatbox-message"><b>{ speaker }:</b> { message }</p>);
              }) }
              <div ref={ chat_bottom_ref } className="chatbox-bottom" />
            </div>
            <div className="chatbox-input-row">
              <textarea onKeyPress={ handleKeys } className="chatbox-input" value={ chatText } onChange={ (e) => { setChatText(e.target.value) } } placeholder="send a message to everyone..." />
              <button ref={ submit_button_ref } className="chatbox-send" onClick={ sendChat }>Send</button>
            </div>
          </div>);
}

const MerseysideHost = ({ gameInstanceData, participantsData, player, setTrackRefs, trackControls, chat }) => {
    const [user] = useAuthState(auth);

    // let controls = (player === user.uid ? (<b>CONTROL PANEL</b>) : '');
    if (player) {
      return(<div className="merseyside-host" id="host">
                <TeamScoreboards gameInstanceData={ gameInstanceData }/>
                <Timer gameId={ gameInstanceData.id } isHost={ user.uid === player } />
                <div className='merseyside-host-podium'>
                  <div className='merseyside-host-placement' id={ 'stream-player-wrapper-' + player }>
                    <VideoSlot id={ player } setTrackRefs={ setTrackRefs } trackControls={ trackControls }></VideoSlot>
                    <div className="placard">{ participantsData[player]?.nickname }{ player === user.uid ? ' (you)' : ' (HOST)' }</div>
                 </div>
               </div>
               <br />
              { user.uid === player ? (<HostControls gameInstanceData={ gameInstanceData } id={ player } />) : ''}

             </div>)
    } else {
      return(<div className="merseyside-host" id="host">
        <div className="placard">The host isn't here yet.</div>
      </div>)
    }
};

/* const Question = () => {
  const [questionData, setQuestionData] = useState(null);
  const [questionLoading, setQuestionLoading] = useState(true);
  const [newQuestionNeeded, setNewQuestionNeeded] = useState(true);
  const shuffler = array => array.sort(() => 0.5 - Math.random());

  return (<div id="question_box">
            <Markup content={ questionData['question'] } />
          </div>)
} */

const MerseysideHarsh = ({ gameInstanceData, participantsData, setTrackRefs, trackControls, chat }) => {
  let gdata = gameInstanceData.data();
  const spectators = gdata.participants.filter(participant => gdata.participant_roles[participant] === 'spectators');
  const team1 =  gdata.participants.filter(participant => gdata.participant_roles[participant] === 'team 1');
  const team2 =  gdata.participants.filter(participant => gdata.participant_roles[participant] === 'team 2');
  const host =  gdata.participants.filter(participant => gdata.participant_roles[participant] === 'host')[0];
  return (<div className='merseyside-gameboard'>
            <Spectators  gameInstanceData={ gameInstanceData } participantsData={ participantsData } selection={ spectators } setTrackRefs={ setTrackRefs } trackControls={ trackControls } />
            <MerseysideHarshContestants gameInstanceData={ gameInstanceData } participantsData={ participantsData } selection={ team1 } teamId={ 1 } host={ host } setTrackRefs={ setTrackRefs } trackControls={ trackControls } chat={ chat } />
            <MerseysideHost gameInstanceData={ gameInstanceData } participantsData={ participantsData } player={ host } setTrackRefs={ setTrackRefs } trackControls={ trackControls } chat={ chat }/>
            <MerseysideHarshContestants gameInstanceData={ gameInstanceData } participantsData={ participantsData } selection={ team2 } teamId={ 2 } host={ host } setTrackRefs={ setTrackRefs } trackControls={ trackControls } chat={ chat }/>
            <Chatbox participantsData={ participantsData } chat={ chat }/>
          </div>);
};

export default MerseysideHarsh;
